<template>
  <v-card class="mx-auto" elevation="0">
    <v-card-title class="card-header fixed-card-title2">
         {{$t('addNewHospitalTooltip')}}
        <v-spacer></v-spacer>
        <v-icon @click="closeDrawer" class="white-icon mr-3">mdi-close</v-icon>
    </v-card-title>
    <v-card-text>
      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
      >
        <v-container>
          <v-row class="pt-2">
            <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12"
            >
              <v-text-field
                  outlined
                  dense
                  hide-details="auto"
                  v-model="hospitalToAdd.hospitalName"
                  :rules="[v => !!v || 'Item is required']"
                  :label="$t('office-name')"
                  :key="'textField' + componentKey"
                  required>

              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12"
            >
              <v-select
                  class="width-text-field2"
                  hide-details
                  dense
                  v-model="hospitalToAdd.medicalInstitutionId"
                  :items="allMedicalInstitutions"
                  item-value="id"
                  item-text="institutionName"
                  :label="$t('medicalInstitution')"
                  :key="'select' + componentKey"
                  outlined
              >

              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12"
            >
              <v-textarea
                  outlined
                  hide-details
                  dense
                  no-resize
                  v-model="hospitalToAdd.description"
                  :label="$t('description')"
                  :key="'textArea' + componentKey"
              >

              </v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12"
            >
              <div class="pt-4">
                <h5 class="heading text-left">{{this.$t('address')}}</h5>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12"
            >
              <v-text-field
                  outlined
                  hide-details="auto"
                  dense
                  v-model="hospitalToAdd.address"
                  :label="$t('address')"
                  :rules="[v => !!v || 'Item is required']"
                  :key="'textField1' + componentKey"
                  required>

              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                class="d-flex pa-1"
                cols="6"
                sm="6"
            >
              <v-text-field
                  outlined
                  hide-details="auto"
                  dense
                  v-model="hospitalToAdd.city"
                  :label="$t('city')"
                  :rules="[v => !!v || 'Item is required']"
                  :key="'textField2' + componentKey"
                  required>

              </v-text-field>
            </v-col>
            <v-col
                class="d-flex pa-1"
                cols="6"
                sm="6"
            >
              <v-text-field
                  outlined
                  hide-details="auto"
                  dense
                  v-model="hospitalToAdd.state"
                  :label="$t('state')"
                  :rules="[v => !!v || 'Item is required']"
                  :key="'textField3' + componentKey"
                  required>

              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                class="d-flex pa-1"
                cols="6"
                sm="6"
            >
              <v-text-field
                  outlined
                  hide-details="auto"
                  dense
                  v-model="hospitalToAdd.zip"
                  :label="$t('zip')"
                  :rules="[v => !!v || 'Item is required']"
                  :key="'textField4' + componentKey"
                  required>

              </v-text-field>
            </v-col>
            <v-col
                class="d-flex pa-1"
                cols="6"
                sm="6"
            >
              <v-text-field
                  outlined
                  hide-details="auto"
                  dense
                  v-model="hospitalToAdd.country"
                  :label="$t('country')"
                  :rules="[v => !!v || 'Item is required']"
                  :key="'textField5' + componentKey"
                  required>

              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions class="fixed-card-actions">
      <v-row dense class="mx-0 px-0 mr-2">
        <v-col cols="6">
          <v-btn
              elevation="0"
              @click="closeDrawer"
              class="cancel-button-style capitalize-text"
          >
            {{$t('cancel')}}
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
              :disabled="!valid"
              @click="saveHospital"
              class="save-button-style"
          >
            {{ $t('save') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import Hospital from '../../data/models/Hospital';
import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';
export default {
	name: 'HopsitalAddDrawer',
	data: () => ({
		valid: false,
    componentKey: 0,
    menu: false,
		hospitalToAdd: new Hospital(),
	}),
	computed: {
    ...mapState({
			allMedicalInstitutions: (state) => state.medicalInstitutions.medicalInstitutions,
      hospitals: (state) => state.hospitals.hospitals,
		}),
	},
	methods: {
    resetComponent () {
      // Reset firstname or any other component state as needed
      this.hospitalToAdd.hospitalName = '';
      this.hospitalToAdd.medicalInstitutionId = '';
      this.hospitalToAdd.description = '';
      this.hospitalToAdd.address = '';
      this.hospitalToAdd.city = '';
      this.hospitalToAdd.state = '';
      this.hospitalToAdd.zip = '';
      this.hospitalToAdd.country = '';
      // Change the key to trigger component reset
      this.componentKey += 1;
    },
		async getHospitals () {
			await this.$store.dispatch('hospitals/getHospitals').then(() => {
        this.closeDrawer();
			});
		},
		async saveHospital () {
			if (this.$refs.form.validate()) {
				const body = {
					medicalInstitutionId: this.hospitalToAdd.medicalInstitutionId,
					hospitalName: this.hospitalToAdd.hospitalName,
					description: this.hospitalToAdd.description,
          contactPerson: null,
					contactPhone: null,
          contactEmail: null,
					address: this.hospitalToAdd.address,
					zip: this.hospitalToAdd.zip,
          city: this.hospitalToAdd.city,
					state: this.hospitalToAdd.state,
					country: this.hospitalToAdd.country,
					addressLatitude: this.hospitalToAdd.addressLatitude,
					addressLongitude: this.hospitalToAdd.addressLongitude,
          hospitalSpetializationId: this.hospitalToAdd.hospitalSpetializationId,
				};
        await this.$store.dispatch('hospitals/createHospital', body).then((res) => {
					if (res) {
            showSuccessAlert(this.$t('successfully-created-organization'));
            this.getHospitals(); // reload the list of hospitals
					} else {
            showErrorAlert(this.$t('failed-operation'));
          }
				});
			} else {
				this.$refs.form.validate();
			}
		},
		save (date) {
			this.$refs.menu.save(date);
		},
		closeDrawer () {
			this.valid = false;
      this.hospitalToAdd.hospitalName = [];
			this.hospitalToAdd = {};
			this.$store.commit('SET_DETAILS_DRAWER', false);
      this.resetComponent();
		},
    async checkAndFixLocationDetails () {
			try {
				if (this.hospitalToAdd.address !== '' && this.hospitalToAdd.city !== '' && this.hospitalToAdd.zip !== '' && this.hospitalToAdd.state !== '' && this.hospitalToAdd.country !== '') {
					var addressForValidation;
					addressForValidation = this.hospitalToAdd.address + ', ' + this.hospitalToAdd.city + ', ' + this.hospitalToAdd.state + ' ' + this.hospitalToAdd.zip + ', ' + this.hospitalToAdd.country;
					const geoLocation = await this.$store.dispatch(
						'googleAPI/getGeoLocation',
						addressForValidation,
					);
					if (!geoLocation) {
						this.hospitalToAdd.addressLatitude = 0.01;
            this.hospitalToAdd.addressLongitude = 0.01;
						return false;
					}
					if (geoLocation.address === undefined || geoLocation.address == null) {
            this.hospitalToAdd.addressLatitude = 0.01;
            this.hospitalToAdd.addressLongitude = 0.01;
						return false;
					}
					const addressComponents = geoLocation.address;
					this.hospitalToAdd.addressLatitude = (geoLocation.lat === undefined || geoLocation.lat === null) ? 0.01 : geoLocation.lat;
					this.hospitalToAdd.addressLongitude = (geoLocation.lon === undefined || geoLocation.lon === null) ? 0.01 : geoLocation.lon;
					this.hospitalToAdd.address = (geoLocation.display_name === undefined || geoLocation.display_name === null) ? this.hospitalToAdd.address : geoLocation.display_name;
					this.hospitalToAdd.city = addressComponents.city || addressComponents.village || addressComponents.suburb || 'N/A';
					this.hospitalToAdd.state = addressComponents.state || 'N/A';
					this.hospitalToAdd.country = addressComponents.country || 'N/A';
					this.hospitalToAdd.zip = addressComponents.postcode || 'N/A';
					return true;
				} else {
					this.hospitalToAdd.addressLatitude = 0.01;
                    this.hospitalToAdd.addressLongitude = 0.01;
					return false;
				}
			} catch (error) {
				return false;
			}
		},
	},
};
</script>
