<template>
    <v-card
        class="mx-auto"
        elevation="0"
    >
        <v-card-title class="card-header text-left fixed-card-title2">
            <v-row>
                <v-col cols="10">
                    <v-toolbar-title >{{$t('addMedicalInstitutionTooltip')}}</v-toolbar-title>
                </v-col>
              <v-spacer></v-spacer>
              <v-icon @click="closeDrawer" class="white-icon mr-3">mdi-close</v-icon>
            </v-row>
        </v-card-title>
        <v-card-text>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
                <!-- Data -->
                <v-container>
                  <v-row>
                    <v-col
                        class="d-flex pa-1"
                        cols="12"
                        sm="12"
                    >
                      <div class="pt-2">
                        <h5 class="heading text-left">{{this.$t('Organization')}}</h5>
                      </div>
                    </v-col>
                  </v-row>
                    <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="12"
                            sm="12"
                        >
                            <v-text-field
                                outlined
                                hide-details="auto"
                                dense
                                v-model="medicalInstitutionToAdd.institutionName"
                                :rules="[v => !!v || 'Item is required']"
                                :label="$t('organization-name')"
                                required
                                :key="'textField1' + componentKey"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="12"
                            sm="12"
                        >
                            <v-textarea
                                no-resize
                                outlined
                                hide-details
                                dense
                                v-model="medicalInstitutionToAdd.description"
                                :label="$t('description')"
                                :key="'textArea' + componentKey"
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="12"
                            sm="12"
                        >
                            <div class="pt-4">
                                <h5 class="heading text-left">{{this.$t('address')}}</h5>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="12"
                            sm="12"
                        >
                            <v-text-field
                                outlined
                                hide-details="auto"
                                dense
                                v-model="medicalInstitutionToAdd.address"
                                :rules="[v => !!v || 'Item is required']"
                                :label="$t('address')"
                                required
                                :key="'textField2' + componentKey"
                            >

                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="6"
                            sm="6"
                        >
                            <v-text-field
                                outlined
                                hide-details="auto"
                                dense
                                v-model="medicalInstitutionToAdd.city"
                                :rules="[v => !!v || 'Item is required']"
                                :label="$t('city')"
                                required
                                :key="'textField3' + componentKey"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col
                            class="d-flex pa-1"
                            cols="6"
                            sm="6"
                        >
                            <v-text-field
                                outlined
                                hide-details="auto"
                                dense
                                v-model="medicalInstitutionToAdd.state"
                                :rules="[v => !!v || 'Item is required']"
                                :label="$t('state')"
                                required
                                :key="'textField4' + componentKey"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="6"
                            sm="6"
                        >
                            <v-text-field
                                outlined
                                hide-details="auto"
                                dense
                                v-model="medicalInstitutionToAdd.zip"
                                :rules="[v => !!v || 'Item is required']"
                                :label="$t('zip')"
                                required
                                :key="'textField5' + componentKey"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col
                            class="d-flex pa-1"
                            cols="6"
                            sm="6"
                        >
                            <v-text-field
                                outlined
                                hide-details="auto"
                                dense
                                v-model="medicalInstitutionToAdd.country"
                                :rules="[v => !!v || 'Item is required']"
                                :label="$t('country')"
                                required
                                :key="'textField6' + componentKey"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="12"
                            sm="12"
                        >
                            <div class="pt-4">
                                <h5 class="heading text-left">{{this.$t('contact')}}</h5>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="12"
                            sm="12"
                        >
                            <v-text-field
                                outlined
                                hide-details="auto"
                                dense
                                v-model="medicalInstitutionToAdd.contactPerson"
                                :rules="[v => !!v || 'Item is required']"
                                :label="$t('contact-person')"
                                required
                                :key="'textField7' + componentKey"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            class="d-flex pa-1"
                            cols="12"
                            sm="12"
                        >
                            <v-text-field
                                outlined
                                hide-details="auto"
                                dense
                                v-model="medicalInstitutionToAdd.contactPhone"
                                :rules="[v => !!v || 'Item is required']"
                                :label="$t('contact-phone')"
                                @input="formatPhoneNumber"
                                required
                                :key="'textField8' + componentKey"
                            >
                            </v-text-field>
                            <v-text-field
                                class="ml-2"
                                outlined
                                hide-details="auto"
                                dense
                                v-model="medicalInstitutionToAdd.contactEmail"
                                :rules="[v => !!v || 'Item is required']"
                                :label="$t('contact-email')"
                                required
                                :key="'textField9' + componentKey"
                                >
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions  class="fixed-card-actions">
            <v-row dense class="mx-0 px-0 mr-2">
                <v-col cols="6">
                    <v-btn
                        @click="closeDrawer"
                        elevation="0"
                        class="cancel-button-style capitalize-text"
                        >
                    {{$t('cancel')}}
                    </v-btn>
                </v-col>
                <v-col cols="6">
                    <v-btn
                        :disabled="!valid"
                        @click="saveMedicalInstitution"
                        class="save-button-style"
                    >
                    {{ $t('save') }}
                    </v-btn>
                </v-col>
            </v-row>
      </v-card-actions>
    </v-card>
</template>

<script>
import { closeWhite } from '@/assets';
import { mapGetters } from 'vuex';
import MedicalInstitution from '../../data/models/MedicalInstitution';
import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';
import { AsYouType } from 'libphonenumber-js';

export default {
	name: 'DashboardCoreAppBar',
	components: {},
	data: () => ({
		closeWhite: closeWhite,
		valid: false,
    componentKey: 0,
    menu: false,
		medicalInstitutionToAdd: new MedicalInstitution(),
        validationErrors: [],
	}),
	computed: {
		...mapGetters({
			delay: 'authentication/getDelay',
		}),
	},
	methods: {
    resetComponent () {
      // Reset firstname or any other component state as needed
      this.medicalInstitutionToAdd.institutionName = '';
      this.medicalInstitutionToAdd.description = '';
      this.medicalInstitutionToAdd.address = '';
      this.medicalInstitutionToAdd.city = '';
      this.medicalInstitutionToAdd.state = '';
      this.medicalInstitutionToAdd.zip = '';
      this.medicalInstitutionToAdd.country = '';
      this.medicalInstitutionToAdd.contactPerson = '';
      this.medicalInstitutionToAdd.contactEmail = '';

      // Change the key to trigger component reset
      this.componentKey += 1;
    },
    formatPhoneNumber (val) {
      this.medicalInstitutionToAdd.contactPhone = new AsYouType().input(val);
    },
		getAllMedicalInstitutions () {
			this.$store.dispatch('medicalInstitutions/getMedicalInstitutions').then(() => {
				this.closeDrawer();
			});
		},
		async saveMedicalInstitution () {
            const waitAddressValidation = await this.checkAndFixLocationDetails();
            if (!waitAddressValidation) {
                // this.validationErrors.push('address');
                // go on with the given addres data
            }

            this.medicalInstitutionToAdd.contactPhone = new AsYouType().input(this.medicalInstitutionToAdd.contactPhone);

			if (this.$refs.form.validate()) {
				const body = {
					institutionName: this.medicalInstitutionToAdd.institutionName,
					description: this.medicalInstitutionToAdd.description,
					contactPerson: this.medicalInstitutionToAdd.contactPerson,
					contactPhone: this.medicalInstitutionToAdd.contactPhone,
					address: this.medicalInstitutionToAdd.address,
					zip: this.medicalInstitutionToAdd.zip,
                    city: this.medicalInstitutionToAdd.city,
					state: this.medicalInstitutionToAdd.state,
					country: this.medicalInstitutionToAdd.country,
					addressLatitude: this.medicalInstitutionToAdd.addressLatitude,
					addressLongitude: this.medicalInstitutionToAdd.addressLongitude,
					contactEmail: this.medicalInstitutionToAdd.contactEmail,
                    mediaServerData: this.medicalInstitutionToAdd.mediaServerData,
				};

                await this.$store.dispatch('medicalInstitutions/createMedicalInstitution', body)
				.then((res) => {
					if (res) {
                        showSuccessAlert(this.$t('successfully-created-organization'));
                        this.getAllMedicalInstitutions(); // reload the list of organizations
					} else {
                        showErrorAlert(this.$t('failed-operation'));
                    }
				});
        this.resetComponent();
      } else {
				this.$refs.form.validate();
			}
		},
		save (date) {
			this.$refs.menu.save(date);
		},
		closeDrawer () {
			this.valid = false;
			this.medicalInstitutionToAdd = {};
			this.$store.commit('SET_DETAILS_DRAWER', false);
      this.resetComponent();
		},
        async checkAndFixLocationDetails () {
			try {
				if (this.medicalInstitutionToAdd.address !== '' && this.medicalInstitutionToAdd.city !== '' && this.medicalInstitutionToAdd.zip !== '' && this.medicalInstitutionToAdd.state !== '' && this.medicalInstitutionToAdd.country !== '') {
					var addressForValidation;
					addressForValidation = this.medicalInstitutionToAdd.address + ', ' + this.medicalInstitutionToAdd.city + ', ' + this.medicalInstitutionToAdd.state + ' ' + this.medicalInstitutionToAdd.zip + ', ' + this.medicalInstitutionToAdd.country;
					const geoLocation = await this.$store.dispatch(
						'googleAPI/getGeoLocation',
						addressForValidation,
					);
					if (!geoLocation) {
						this.medicalInstitutionToAdd.addressLatitude = 0.01;
                        this.medicalInstitutionToAdd.addressLongitude = 0.01;
						return false;
					}
					if (geoLocation.address === undefined || geoLocation.address == null) {
                        this.medicalInstitutionToAdd.addressLatitude = 0.01;
                        this.medicalInstitutionToAdd.addressLongitude = 0.01;
						return false;
					}

					const addressComponents = geoLocation.address;

					this.medicalInstitutionToAdd.addressLatitude = (geoLocation.lat === undefined || geoLocation.lat === null) ? 0.01 : geoLocation.lat;
					this.medicalInstitutionToAdd.addressLongitude = (geoLocation.lon === undefined || geoLocation.lon === null) ? 0.01 : geoLocation.lon;
					this.medicalInstitutionToAdd.address = (geoLocation.display_name === undefined || geoLocation.display_name === null) ? this.medicalInstitutionToAdd.address : geoLocation.display_name;
					this.medicalInstitutionToAdd.city = addressComponents.city || addressComponents.village || addressComponents.suburb || 'N/A';
					this.medicalInstitutionToAdd.state = addressComponents.state || 'N/A';
					this.medicalInstitutionToAdd.country = addressComponents.country || 'N/A';
					this.medicalInstitutionToAdd.zip = addressComponents.postcode || 'N/A';
					return true;
				} else {
					this.medicalInstitutionToAdd.addressLatitude = 0.01;
                    this.medicalInstitutionToAdd.addressLongitude = 0.01;
					return false;
				}
			} catch (error) {
				return false;
			}
		},
	},
};
</script>
