<template>
    <v-card
        class="mx-auto"
        elevation="0">
      <v-card-title class="card-header text-left">
        <v-row>
          <v-col cols="10">
            <v-toolbar-title >{{$t('add_verifire')}}</v-toolbar-title>
          </v-col>
          <v-col cols="2" class="text-right mt-n1">
            <v-icon color="white" @click="closeDrawer">mdi mdi-close</v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation>
          <!-- Data -->
          <v-container>
            <!-- Divider -->
            <v-row>
              <v-divider class="mx-1 my-2"></v-divider>
            </v-row>
            <v-row>
              <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12">
                <v-text-field
                  outlined
                  hide-details
                  dense
                  v-model="verifierToAdd.rvDomain"
                  :rules="[v => !!v || 'Item is required']"
                  :label="$t('domainname')"
                  required>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12">
                <v-text-field
                    outlined
                    hide-details
                    dense
                    v-model="verifierToAdd.lowValue"
                    :rules="[v => !!v || 'Item is required']"
                    :label="$t('lowvalue')"
                    required>
                  </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12">
                <v-text-field
                    outlined
                    hide-details
                    dense
                    v-model="verifierToAdd.shortDescription"
                    :rules="[v => !!v || 'Item is required']"
                    :label="$t('shortdescription')"
                    required>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12">
                <v-text-field
                    outlined
                    hide-details
                    dense
                    v-model="verifierToAdd.longDescription"
                    :label="$t('longdescription')"
                    required>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12">
                <v-text-field
                  outlined
                  hide-details
                  dense
                  v-model="verifierToAdd.shortDescriptionEsp"
                  :label="$t('shortdescriptionesp')"
                  required>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="d-flex pa-1"
                cols="12"
                sm="12">
                <v-text-field
                    outlined
                    hide-details
                    dense
                    v-model="verifierToAdd.longDescriptionEsp"
                    :label="$t('longdescriptionesp')"
                    required>
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions class="fixed-card-actions">
        <v-row class="mb-0">
          <v-col cols="6">
            <v-btn
                @click="closeDrawer"
                elevation="0"
                outlined
                width="100%"
                class="capitalize-text">
              {{$t('cancel')}}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
                :disabled="!valid"
                color="chips"
                width="100%"
                class="capitalize-text white-color"
                @click="saveVerifier">
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
</template>

<script>
import { closeWhite } from '@/assets/index';
import { mapGetters } from 'vuex';
import Verifier from '../../data/models/Verifier';
import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';

export default {
	name: 'DashboardCoreAppBar',
	components: {},
	data: () => ({
		closeWhite: closeWhite,
		valid: true,
		menu: false,
		verifierToAdd: new Verifier(),
	}),
	async mounted () {
	},
	computed: {
		...mapGetters({
			delay: 'authentication/getDelay',
		}),
	},
	methods: {
		getAllVerifiers () {
			this.$store.dispatch('verifiers/getAllVerifiers').then(() => {
				this.closeDrawer();
			});
		},
		async saveVerifier () {
			if (this.$refs.form.validate()) {
				const body = {
					rvDomain: this.verifierToAdd.rvDomain,
					lowValue: this.verifierToAdd.lowValue,
					shortDescription: this.verifierToAdd.shortDescription,
					longDescription: this.verifierToAdd.longDescription,
					shortDescriptionEsp: this.verifierToAdd.shortDescriptionEsp,
					longDescriptionEsp: this.verifierToAdd.longDescriptionEsp,
				};

				await this.$store.dispatch('verifiers/createVerifire', body).then(res => {
					if (res.data.resFlag) {
						showSuccessAlert(this.$t('success-operation'));
						this.getAllVerifiers(); // refresh the verifiers list
					} else {
						showErrorAlert(this.$t('failed-operation'));
          }
				});
			} else {
				this.$refs.form.validate();
			}
		},
		save (date) {
			this.$refs.menu.save(date);
		},
		closeDrawer () {
			this.valid = false;
			this.verifierToAdd = {};
			this.$store.commit('SET_DETAILS_DRAWER', false);
		},
	},
};
</script>
