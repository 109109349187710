<template>
  <div>
    <v-card-title class="text-left card-header fixed-card-title">
      <v-row>
        <v-col cols="10" class="align-center d-flex">
          <v-toolbar-title>{{$t('add-monitoring-plan')}}</v-toolbar-title>
        </v-col>
        <v-col cols="2" class="text-right">
          <v-spacer></v-spacer>
          <v-icon @click="closeDrawer" class="white-icon mr-2">mdi-close</v-icon>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="margin-top90 mb-n10">
      <v-text-field
          v-model="patientActiveTreatmentPlan.tpName"
          :label= "$t('change-title')"
          dense
          outlined
          hide-details
      ></v-text-field>
      <v-textarea
          v-model="patientActiveTreatmentPlan.tpDescription"
          outlined
          :label= "$t('change-description')"
          hide-details
          class="pt-3"
          no-resize
      ></v-textarea>
    </v-card-text>
    <v-card-text>
      <v-col class="px-0">
        <h6 class="device-font mb-1">{{$t('Device Measurements')}}</h6>
        <div class="text-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div>
                <v-btn v-on="on" v-bind="attrs" class="full-width" color="transparent">{{$t('add-measurement')}}</v-btn>
              </div>
            </template>
            <v-list>
              <v-list-item
                  v-for="(item, index) in filteredDevices"
                  :key="index"
                  class="addmeasurment-font"
                  @click="addingDevice(item)"
              >
                <v-list-item-title class="font-size-small2">
                  <template v-if="item.body.deviceType===3">
                    <img src="@/assets/icons/blood_pressure_black.svg" alt="Blood Pressure Icon" class="device-img">
                    {{ $i18n.locale === 'en' ? item.deviceName : item.deviceNameEsp}}
                  </template>
                  <template v-if="item.body.deviceType===6">
                    <img src="@/assets/icons/glucose_meter_black.svg" alt="Glucose Meter Icon" class="device-img">
                    {{$i18n.locale === 'en' ? item.deviceName : item.deviceNameEsp}}
                  </template>
                  <template v-if="item.body.deviceType===7">
                    <img src="@/assets/icons/glucose_meter_black.svg" alt="Glucose Meter Icon" class="device-img">
                    {{$i18n.locale === 'en' ? item.deviceName : item.deviceNameEsp}}
                  </template>
                  <template v-if="item.body.deviceType===2">
                    <img src="@/assets/icons/oxymeter_black.svg" alt="Oximeter Icon" class="device-img">
                    {{$i18n.locale === 'en' ? item.deviceName : item.deviceNameEsp}}
                  </template>
                  <template v-if="item.body.deviceType===1">
                    <img src="@/assets/icons/thermometer_black.svg" alt="Thermometer Icon" class="device-img">
                    {{$i18n.locale === 'en' ? item.deviceName : item.deviceNameEsp}}
                  </template>
                  <template v-if="item.body.deviceType===5">
                    <img src="@/assets/icons/scale_black.svg" alt="Weight scale Icon" class="device-img">
                    {{$i18n.locale === 'en' ? item.deviceName : item.deviceNameEsp}}
                  </template>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-col>
      <v-row dense>
        <v-col v-for="item in assignDevices" :key="item.id" cols="12">
          <v-card class="elevation-2">
            <div class="px-2 text-align">
              <template v-if="item.deviceType===3">
                <img src="@/assets/icons/blood_pressure_black.svg" alt="Blood Pressure Icon" class="device-img">
              </template>
              <template v-if="item.deviceType===6">
                <img src="@/assets/icons/glucose_meter_black.svg" alt="Glucose Meter Icon" class="device-img">
              </template>
              <template v-if="item.deviceType===7">
                <img src="@/assets/icons/glucose_meter_black.svg" alt="Glucose Meter Icon" class="device-img">
              </template>
              <template v-if="item.deviceType===2">
                <img src="@/assets/icons/oxymeter_black.svg" alt="Oximeter Icon" class="device-img">
              </template>
              <template v-if="item.deviceType===1">
                <img src="@/assets/icons/thermometer_black.svg" alt="Thermometer Icon" class="device-img">
              </template>
              <template v-if="item.deviceType===5">
                <img src="@/assets/icons/scale_black.svg" alt="Weight scale Icon" class="device-img">
              </template>
              <h6 class="font-size-small3"> {{ $i18n.locale === 'en' ? item.name : item.nameEsp }}</h6>
              <h6 class="font-size14"> {{ item.manufacturer }}</h6>
              <v-icon class="ml-auto my-3 black-icon" color="#29085A" @click="showMethods(item)">mdi mdi-chevron-down</v-icon>
            </div>
            <div class="padding10" v-if="selectedDevice === item.id && !showInputs">
              <v-row >
                <v-col v-for="(keys, i) in item.deviceDefaultValuesJsonNames" :key="i" cols="6" >
                  <v-text-field
                      type="number"
                      hide-details
                      outlined
                      dense
                      :rules="[v => !!v || 'This field is required', validateValue]"
                      v-model="item.deviceDefaultValuesJson[keys]"
                      :label="stringFormater(keys) + ' ' + unitFormater(keys, item)"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-card-actions class="pr-0" v-if="!showInputs">
                <v-row dense>
                  <v-col class="d-flex align-center " cols="6">
                    <v-row class="d-flex align-center justify-space-between" dense>
                      <v-col align-self="center" cols="3">
                        <v-icon @click="deleteMedicalDevice(item)" class="color-black"  >mdi-delete</v-icon>
                      </v-col>
                      <v-col class="d-flex justify-start align-center">
                        <v-icon @click="isManual(item)" :style="{ color: item.isManualMeasurement ? 'black' : 'grey' }">
                          {{ item.isManualMeasurement ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}
                        </v-icon>
                        <span class="ml-1">{{ $t('manual')}}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6" class="px-2 pr-1">
                    <v-btn small @click="saveParameters(item)"  class="save-button-style" :disabled="isSaveDisabled">{{ $t("save") }}</v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
      <v-col class="ml-1 mt-n5">
      <h6 class="device-font" >{{$t('wellness-assessments')}}</h6>
      </v-col>
      <div class="px-4 mt-n2">
      <v-btn color="transparent" @click="openWellnessCard" class="py-5 full-width">+ {{$t('assessments')}}</v-btn>
    </div>
    <v-col class="px-4">
      <div class="full-width" v-if="openWellness">
        <v-card class="custom-container" elevation="5">
          <div class="flex-container">
            <div class="text-align">
              <img :src="wellnessAssessmentBlack" class="small-height">
              <div class="color-black ml-3">{{$t('wellness-assessments')}}</div>
            </div>
          </div>
          <div>
            <v-col cols="12">
              <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field :label="$t('start-date')" v-model="date" prepend-icon="mdi-calendar" readonly hide-details dense outlined v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker range v-model="date" no-title scrollable >
                  <v-spacer></v-spacer>
                  <v-btn text class="color-primary" @click="menu = false">{{ $t('cancel') }}</v-btn>
                  <v-btn text class="color-primary" @click="$refs.menu.save(date)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </div>
          <div class="small-padding2">
            <v-select outlined dense :label="$t('Assessments')" :items="surveyTemplates" item-text="templateName" item-value="id" v-model="selectedTemplate"></v-select>
            <v-select outlined dense :label="$t('measurement-period')" style="margin-top:-10px;" :items="reversedSurveyPeriods" v-model="selectedPeriod" item-text="shortDescription" item-value="lowValue">
            </v-select>
            <v-card-actions class="mt-n3">
              <v-btn @click="assignSurveyTemplatetoPatient" class="save-button-style">{{ $t("save") }}</v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </div>
    </v-col>
    <v-col  v-for="(item, index) in surveyForPatientReport" :key="index" class="py-0 mx-1">
      <div v-for="(recurrence) in item.surveysList" :key="recurrence.id" class="py-1" v-show="selectedCardIndex === recurrence.id || !clickedEdit">
        <v-card>
          <div class="d-flex align-center">
            <v-icon class="mx-2" :class="{ 'green-icon': recurrence.status }" small >mdi-checkbox-blank-circle</v-icon>
            <img :src="wellnessAssessmentBlack" class="small-height">
            <div class="wellness-text2" >{{recurrence.templateName}}</div>
            <v-icon @click="userEditing(recurrence)" v-if=!clickedEdit class="ml-auto my-3 cursor" flat>mdi mdi-chevron-down</v-icon>
            <v-icon @click="cancelEditing" v-if=clickedEdit class="ml-auto cursor">mdi mdi-chevron-up</v-icon>
          </div>
          <div v-if="clickedEdit" class="mt-2">
            <div v-if="clickedEdit" class="text-left ml-16 font-weight-bold px-1 mt-n3">{{recurrence.templateDescription}}</div>
            <v-select class="ml-13 mr-5 mt-1 margin-top-10" hide-details outlined dense :label="$t('recurrence')"  :items="reversedSurveyPeriods" v-model="selectedPeriodEdit" item-text="shortDescription" item-value="lowValue"></v-select>
            <v-col
                cols="12"
            >
              <v-menu :ref="'menu_' + recurrence.id" v-model="recurrence.menuOpen" :close-on-content-click="false" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field  class="mx-2" :label="$t('start-date')" v-model="date2" prepend-icon="mdi-calendar" readonly hide-details dense outlined v-bind="attrs" v-on="on"></v-text-field>
                </template>
                <v-date-picker color="#29085A" range v-model="date2" scrollable @close="handleMenuClose">
                  <v-spacer></v-spacer>
                  <v-btn text class="color-primary" @click="closeMenu(recurrence)">{{$t('cancel')}}</v-btn>
                  <v-btn text class="color-primary" @click="closeMenu(recurrence)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </div>
          <div class="small-padding2" v-if="clickedEdit">
            <v-card-actions >
              <v-switch class="mt-n2 margin-top1" v-if="clickedEdit" v-model="recurrence.status" @change="disableSurvey(recurrence)"></v-switch>
              <v-btn v-if="clickedEdit" color="#29085A" class="ml-auto mt-n5 white-icon"  @click="updateSurvey(recurrence)" :disabled="selectedPeriodEdit===null || date2 === null">{{$t('save')}}</v-btn>
              <!-- Add other action buttons or icons as needed -->
            </v-card-actions>
          </div>
        </v-card>
      </div>
    </v-col>
    <v-card-actions class="fixed-card-actions">
      <v-row dense class="pa-0">
        <v-col cols="6">
          <v-btn
              @click="closeDrawer"
              elevation="0"
              class="cancel-button-style capitalize-text"
          >
            {{$t('cancel')}}
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
              @click="handleChangeTitleDescription"
              class="save-button-style"
          >
            {{$t('save')}}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { letterM, letterA, closeWhite, wellnessAssessmentBlack, thermometerPrimary } from '@/assets';

import { fromTo } from '@/utils/unit-converter.js';
import { showErrorAlert, showSuccessAlert } from '@/utils/utilities';

export default {
  components: {},
  props: ['dialog', 'currentDevice', 'commingType', 'video'],
  data () {
    return {
      description: '',
      CloseWhite: closeWhite,
      letterM: letterM,
      ThermometerPrimary: thermometerPrimary,
      date: null,
      date2: null,
      letterA: letterA,
      selectedTemplate: null,
      menu: false,
      selectedPeriod: null,
      assignDevices: [],
      wellnessAssessmentBlack: wellnessAssessmentBlack,
      openWellness: false,
      createDropdownStatus: false,
      filteredDevices: [],
      createdevice: [],
      monitoringDevice: [],
      expandedMonitoringItem: [],
      expanded: [],
      headers: [
        {
          text: this.$t('device-type'),
          align: 'start',
          value: 'name',
        },
        {
          text: this.$t('manufacturer'),
          align: 'end',
          value: 'add',
        },
        {
          sortable: false,
          align: 'end',
          value: 'add',
        },
      ],
      headers2: [
        {
          align: 'start',
          sortable: false,
          value: 'deviceTypeTitle',
          width: '0%',
        },
        {
          text: this.$t('device-type'),
          align: 'start',
          value: 'deviceTypeTitle',
        },
      ],
      loader: null,
      loading: false,
      loadingForm: false,
      time1: null,
      menu1: false,
      modal1: false,
      buttonDisabled: true,
      time2: null,
      changeTemplate: null,
      changeDescription: null,
      menu2: false,
      clickedEdit: false,
      isSaveDisabled: false,
      modal2: false,
      time3: null,
      selectedPeriodEdit: null,
      itemData: null,
      selectedCardIndex: -1,
      menu3: false,
      showInputs: true,
      modal3: false,
      time4: null,
      menu4: false,
      modal4: false,
      formChangeTemplate: '',
      formChangeDescription: '',
      formSelectedPeriod: '',
      selectedDevice: null,
      treatmentSection: 0,
      deviceManufacturers: [],
      manufacturer: null,
      trustedHubIdentifier: null,
      selectiontimes: [
        { name: this.$t('one-time-per-day'), id: 1 },
        { name: this.$t('two-time-per-day'), id: 2 },
        { name: this.$t('three-time-per-day'), id: 3 },
        { name: this.$t('four-time-per-day'), id: 4 },
      ],
    };
  },
  computed: {
    ...mapGetters({
      userSettings: 'authentication/getUserSettings',
      getRole: 'authentication/getRole',
    }),
    ...mapState({
      authenticated: state => state.authentication.userData,
      detailedUser: (state) => state.users.detailedUser,
      devicetemplates: state => state.medicaldevice.deviceTemplates,
      surveyTemplates: (state) => state.mentalhealth.surveys,
      patientActiveTreatmentPlan: (state) => state.medicaldevice.patientActiveTreatmentPlan,
      surveyPeriods: (state) => state.verifiers.surveyRecurrence,
      surveyForPatientReport: (state) => state.mentalhealth.surveyReportForPatient,
      auth: (state) => state.authentication.userData,
      treamentPlansFromRepo: (state) => state.treatmentplan.treamentPlans,
      verifiersFromRepo: (state) => state.verifiers.verifiers,
    }),
    isButtonDisabled () {
      return this.selectedPeriodEdit !== '';
    },
    reversedSurveyPeriods () {
      if (!Array.isArray(this.surveyPeriods)) {
        return [];
      }
      return [...this.surveyPeriods].reverse();
    },
    concentration () {
      return this.userSettings?.Concentration;
    },
    isForMentalHealth () {
      return this.detailedUser?.userData?.patientMetadata?.mentalHealth;
    },
    unit () {
      return this.userSettings?.Units;
    },
    userData () {
      return this.detailedUser;
    },
    trustedHubs () {
      return this.userData?.trustedHubsData ?? [];
    },
    valid () {
      if ([1, 6].includes(this.deviceType)) {
        if ((this.value1 === '' || this.value1 === null) ||
            (this.value2 === '' || this.value2 === null)) {
          return true;
        }
        return false;
      } else if (this.deviceType === 3) {
        if ((this.value1 === '' || this.value1 === null) ||
            (this.value2 === '' || this.value2 === null) ||
            (this.value3 === '' || this.value3 === null) ||
            (this.value4 === '' || this.value4 === null) ||
            (this.value5 === '' || this.value5 === null) ||
            (this.value6 === '' || this.value6 === null)
        ) {
          return true;
        }
        return false;
      } else if (this.deviceType === 7) {
        if ((this.value1 === '' || this.value1 === null) ||
            (this.value2 === '' || this.value2 === null) ||
            (this.value3 === '' || this.value3 === null) ||
            (this.value4 === '' || this.value4 === null)) {
          return true;
        }
        return false;
      } else if (this.deviceType === 2) {
        if ((this.value0 === '' || this.value0 === null) ||
            (this.value1 === '' || this.value1 === null) ||
            (this.value2 === '' || this.value2 === null) ||
            (this.value3 === '' || this.value3 === null) ||
            (this.value4 === '' || this.value4 === null)) {
          return true;
        }
        return false;
      }
      return false;
    },
  },
  watch: {
    selectedPeriodEdit: 'checkButtonDisabled',
    async loader () {
      if (this.loader !== null) {
        const l = this.loader;
        this[l] = !this[l];

        await this.save()
            .then(() => {
              this[l] = false;
            })
            .catch(() => {
              this[l] = false;
            });

        this.loader = null;
      }
    },
    async manufacturer (val) {
      if (val) {
        await this.deviceTreatmentPlan(this.localDeviceManufacturer);
      }
    },
  },
  async mounted () {
    await this.getSurveyRecurrencePeriod();
    const header = { domainsList: 'MEDICAL_DEVICE_MANUFACTURER' };
    await this.$store.dispatch('verifiers/getVerifiersForMultipleDomains', header).then(async () => {
      await this.initialize();
    });
    await this.getMentalHealthSurveyTemplates();
  },
  methods: {
    cancelEditing () {
      this.clickedEdit = false;
    },
    showMethods (item) {
      this.showInputs = !this.showInputs;
      this.selectedDevice = item.id;
    },
    async getSurveys () {
      const headers = {
        patientId: this.detailedUser.userData.id,
        filterCase: 'ALL',
        limitForOccurrences: 50,
      };
      await this.$store.dispatch('mentalhealth/getSurveyReportForPatient', headers);
    },
    disableSurvey (recurrence) {
      const body = {
        id: recurrence.id,
        StartDate: recurrence.startDate,
        EndDate: recurrence.endDate,
        templateName: recurrence.templateName,
        templateDescription: recurrence.templateDescription,
        recurrenceRuleForSurvey: recurrence.recurrenceRuleForSurvey,
        status: recurrence.status === false ? 0 : 1,
      };

      this.$store.dispatch('mentalhealth/updateSurvey', body).then(res => {
        if (res.resFlag) {
          showSuccessAlert('Assessment Updated ! ');
        } else {
          showSuccessAlert(res.msg);
        }
      });
      this.clickedEdit = true;
      this.selectedPeriodEdit = null;
      this.date2 = null;
    },
    checkButtonDisabled () {
      this.buttonDisabled = !this.selectedPeriodEdit;
    },
    closeMenu (recurrence) {
      recurrence.menuOpen = false;
    },
    handleDateChange (value) {
      // Save the start and end dates based on whether date2 is a single date or a range
      this.date2 = value;
    },
    handleMenuClose () {
      // Handle menu close here
    },
    populateForm (recurrence) {
      this.formChangeTemplate = recurrence.templateName;
      this.formChangeDescription = recurrence.templateDescription;
    },
    updateSurvey (item) {
      let startDate = null;
      let endDate = null;

      if (Array.isArray(this.date2)) {
        startDate = this.date2[0];
        endDate = this.date2[1] ? this.date2[1] : null;
      } else {
        startDate = this.date2;
      }
      const body = {
        id: item.id,
        StartDate: startDate,
        EndDate: endDate,
        templateName: item.templateName,
        templateDescription: item.templateDescription,
        recurrenceRuleForSurvey: this.selectedPeriodEdit,
        status: 1,
      };

      this.$store.dispatch('mentalhealth/updateSurvey', body).then(res => {
        if (res.resFlag) {
          showSuccessAlert('Assessment Updated!');
        } else {
          showSuccessAlert(res.msg);
        }
      });
      this.clickedEdit = false;
      this.selectedPeriodEdit = null;
      this.date2 = null;
      this.getSurveys();
    },
    userEditing (item) {
      this.clickedEdit = true;
      this.selectedCardIndex = item.id;
      this.populateForm(item);
      this.openWellness = false;
    },
    async deleteSurveyById (item) {
      const body = {
        id: item.id,
      };
      await this.$store.dispatch('mentalhealth/deleteSurveyById', body.id).then(res => {
        showSuccessAlert('Survey is deleted!');
      });
      await this.getMentalHealthSurveyTemplates();
    },
    async handleChangeTitleDescription () {
      const body = {
        treatmentPlanId: this.patientActiveTreatmentPlan.id,
        tpName: this.patientActiveTreatmentPlan.tpName,
        tpDescription: this.patientActiveTreatmentPlan.tpDescription,
      };
      const res = await this.$store.dispatch('treatmentplan/updateTreatmentPlan', body);
      if (!res.message && res.errors === undefined) {
        this.$emit('created');
      }
      await this.$store.dispatch('treatmentplan/getTreatmentPlansForPatient', this.detailedUser.userData.id);

      this.closeDrawer();
    },
    async addingDevice (val) {
      const body = {
        deviceName: val.body.deviceName,
        deviceType: val.body.deviceType,
        hubIdentifier: val.body.hubIdentifier,
        manufacturerId: val.body.manufacturerId,
        manufacturerName: val.body.manufacturerName,
      };
      await this.$store.dispatch('medicaldevice/createMedicalDevice', body).then(async () => {
        await this.assignToTreatmentPlan(val);

        this.initialize();
        // PROBLEM :
        // this.assignToTreatmentPlan(val);
      });
    },
    selectDevice (device) {
      this.createdevice = device;
    },
    checkTemplateName () {
      if (this.changeTemplate === '') {
        this.buttonDisabled = true;
      }
    },
    checkDescription () {
      if (this.changeDescription === '') {
        this.buttonDisabled = true;
      }
    },
    checkRecurrence () {
      if (this.selectedPeriod === '') {
        this.buttonDisabled = true;
      }
    },
    // Your other methods here
    validateValue (value) {
      const deviceType3Item = this.assignDevices.find(item => item.deviceType === 3);

      if (deviceType3Item) {
        const extremeValues = JSON.parse(deviceType3Item.deviceDefaultExtremeValuesJson);

        const systolicMin = extremeValues.SystolicMin;
        const systolicMax = extremeValues.SystolicMax;
        const diastolicMin = extremeValues.DiastolicMin;
        const diastolicMax = extremeValues.DiastolicMax;
        const heartbeatMin = extremeValues.HeartBeatMin;
        const heartbeatMax = extremeValues.HeartBeatMax;

        if (value <= systolicMin || value >= systolicMax ||
            value <= diastolicMin || value >= diastolicMax ||
            value <= heartbeatMin || value >= heartbeatMax) {
          this.isSaveDisabled = true;
          return false; // Validation failed
        }
      }
      this.isSaveDisabled = false;
      return true; // Validation passed
    },
    async assignSurveyTemplatetoPatient () {
      let startDate = null;
      let endDate = null;

      if (Array.isArray(this.date)) {
        startDate = this.date[0];
        endDate = this.date[1] ? this.date[1] : null;
      } else {
        startDate = this.date;
      }

      const body = {
        patientUserId: this.detailedUser.userData.id,
        surveyTemplateId: this.selectedTemplate,
        StartDate: startDate,
        EndDate: endDate,
        recurrenceRuleForSurvey: this.selectedPeriod,
        hospitalId: this.auth.hospitalAssignedData.id,
      };
      await this.$store.dispatch('mentalhealth/assignSurveyTemplatetoPatient', body).then(res => {
        if (res.resFlag) {
          showSuccessAlert(res.msg);
        } else {
          showErrorAlert('Survey not created!');
        }
      });
      await this.getSurveys();

      this.closeDrawer();
    },
    async getSurveyRecurrencePeriod () {
      await this.$store.dispatch('verifiers/getSurveyRecurrencePeriod');
    },
    async getMentalHealthSurveyTemplates () {
      const body = {
        hospitalId: this.auth.hospitalAssignedData.id,
      };
      await this.$store.dispatch('mentalhealth/getMentalHealthSurveyTemplates', body.hospitalId);
    },
    async openWellnessCard () {
      this.openWellness = !this.openWellness;
      await this.getSurveyRecurrencePeriod();
    },
    closeDrawer () {
      this.$store.commit('SET_DETAILS_DRAWER', false);
    },
    async saveTimers (val) {
      await this.$store.dispatch('treatmentplan/updateDeviceTimes', val).then(async () => {
        await this.initialize();
      });
    },
    toggleDropdown () {
      this.createDropdownStatus = !this.createDropdownStatus;
    },
    async assignToTreatmentPlan (val) {
      var body = {
        treatmentPlanId: this.patientActiveTreatmentPlan.id,
        deviceType: val.body.deviceType,
        measurementPeriod: 'D',
        measurementsCount: 1,
        time1: '08:00',
      };
      await this.$store.dispatch('treatmentplan/assignDevice', body);
      // PATIENT ID
      await this.$store.dispatch('treatmentplan/getTreatmentPlansForPatient', this.detailedUser.userData.id);
    },
    async deAssignFromTreatmentPlan (val) {
      await this.$store.dispatch('treatmentplan/deassignDevice', val.id).then((res) => {
        // this.initialize();
      });
      await this.$store.dispatch('treatmentplan/getTreatmentPlansForPatient', this.detailedUser.userData.id);
    },
    async deleteMedicalDevice (val) {
      var body = {
        id: val.id,
        patientId: this.detailedUser.userData.id,
      };
      try {
        await this.$store.dispatch('medicaldevice/deleteMedicalDevice', body).then(async res => {
          if (res.resFlag) {
            const deviceTypeIdToDelete = res.data.deviceType;

            // loop monitoringDevice
            for (let i = 0; i < this.monitoringDevice.length; i++) {
              const currentTretmentPlanDevice = this.monitoringDevice[i];
              if (currentTretmentPlanDevice.deviceType === deviceTypeIdToDelete) {
                // here we call API to deassign from TP
                await this.deAssignFromTreatmentPlan(currentTretmentPlanDevice);
                break;
              }
            }
          }
        });
        await this.initialize();
      } catch (error) {
        if (error.response && error.response.status === 403) {
          this.$store.commit('alerts/add', {
            id: 'addedHospital',
            type: 'error',
            color: 'main_red',
            // message: 'Forbidden operation. Contact administrator',
            // message: error.response.data.msg,
            message: this.$t('failed'),
          });
        } else {
          this.$store.commit('alerts/add', {
            id: 'addedHospital',
            type: 'error',
            color: 'main_red',
            // message: 'Forbidden operation. Contact administrator',
            // message: 'General error happen...',
            message: this.$t('failed'),
          });
        }
        this.$store.dispatch('alerts/clearWithDelay', this.delay);
      }
    },
    async saveParameters (val) {
      const body = {
        deviceDefaultValuesJson: this.convertForStore(val),
        patientId: this.detailedUser.userData.id,
        deviceIdentifier: val.deviceIdentifier,
      };

      try {
        const response = await this.$store.dispatch('medicaldevice/updateDeviceDefaultValuesJsonForMedicalDevice', body);
        if (response.resFlag) {
          this.initialize();
        }
      } catch (error) {
        // console.log(error);
      }
    },
    stringFormater (val) {
      return this.$t(val);
    },
    unitFormater (val, item) {
      var units = '';
      switch (item.deviceType) {
        case 1:
          units = this.userSettings.Units.toLowerCase() === 'metric' ? '(°C)' : '(°F)';
          break;
        case 2:
          if (val.toLowerCase() === 'spo2') {
            units = '(%)';
          }
          break;
        case 3:
          if (val === 'SystolicMin') {
            units = '(mmHg)';
          }
          if (val === 'SystolicMax') {
            units = '(mmHg)';
          }
          if (val === 'SystolicMin') {
            units = '(mmHg)';
          }
          if (val === 'DiastolicMax') {
            units = '(mmHg)';
          }
          if (val === 'DiastolicMin') {
            units = '(mmHg)';
          }
          break;
        case 6:
          units = this.userSettings.Concentration.toLowerCase() === 'mg/dl' ? '(' + this.$t('glucose-mg-dL') + ')' : '(' + this.$t('glucose-mmol-L') + ')';
          break;
        case 7:
          units = this.userSettings.Concentration.toLowerCase() === 'mg/dl' ? '(' + this.$t('glucose-mg-dL') + ')' : '(' + this.$t('glucose-mmol-L') + ')';
          break;
        default:
          break;
      }

      return units;
    },
    // setValues (item, defaultValues) {
    // 	switch (item.lowValue) {
    // 	case 1:
    // 		if (this.userSettings.Units === 'IMPERIAL') {
    // 			this.value1 = defaultValues ? fromTo(defaultValues.MinTemperature, 'C', 'F').toFixed(2) : null;
    // 			this.value2 = defaultValues ? fromTo(defaultValues.MaxTemperature, 'C', 'F').toFixed(2) : null;
    // 		} else {
    // 			this.value1 = defaultValues ? defaultValues.MinTemperature : null;
    // 			this.value2 = defaultValues ? defaultValues.MaxTemperature : null;
    // 		}
    // 		break;
    // 	case 2:
    // 		this.value0 = defaultValues ? defaultValues.SpO2 : null;
    // 		this.value1 = defaultValues ? defaultValues.HeartBeatMin : null;
    // 		this.value2 = defaultValues ? defaultValues.HeartBeatMax : null;
    // 		this.value3 = defaultValues ? defaultValues.PiMin : null;
    // 		this.value4 = defaultValues ? defaultValues.PiMax : null;
    // 		break;
    // 	case 3:
    // 		this.value1 = defaultValues ? defaultValues.DiastolicMin : null;
    // 		this.value2 = defaultValues ? defaultValues.DiastolicMax : null;
    // 		this.value3 = defaultValues ? defaultValues.HeartBeatMin : null;
    // 		this.value4 = defaultValues ? defaultValues.HeartBeatMax : null;
    // 		this.value5 = defaultValues ? defaultValues.SystolicMin : null;
    // 		this.value6 = defaultValues ? defaultValues.SystolicMax : null;
    // 		break;
    // 	case 6:
    // 		if (this.userSettings.Concentration === 'mg/dl') {
    // 			this.value1 = defaultValues ? (defaultValues.BloodSugarLevelMin * 18).toFixed(2) : null;
    // 			this.value2 = defaultValues ? (defaultValues.BloodSugarLevelMax * 18).toFixed(2) : null;
    // 		} else {
    // 			this.value1 = defaultValues ? defaultValues.BloodSugarLevelMin : null;
    // 			this.value2 = defaultValues ? defaultValues.BloodSugarLevelMax : null;
    // 		}
    // 		break;
    // 	case 7:
    // 		if (this.userSettings.Concentration === 'mg/dl') {
    // 			this.value1 = defaultValues ? (defaultValues.BloodSugarLevelMin * 18).toFixed(2) : null;
    // 			this.value2 = defaultValues ? (defaultValues.BloodSugarLevelMax * 18).toFixed(2) : null;
    // 			this.value3 = defaultValues ? (defaultValues.CholesterolLevelMin * 38.67).toFixed(2) : null;
    // 			this.value4 = defaultValues ? (defaultValues.CholesterolLevelMax * 38.67).toFixed(2) : null;
    // 		} else {
    // 			this.value1 = defaultValues ? defaultValues.BloodSugarLevelMin : null;
    // 			this.value2 = defaultValues ? defaultValues.BloodSugarLevelMax : null;
    // 			this.value3 = defaultValues ? defaultValues.CholesterolLevelMin : null;
    // 			this.value4 = defaultValues ? defaultValues.CholesterolLevelMax : null;
    // 		}
    // 		break;
    // 	}
    // },
    convertForStore (item) {
      function parseNumbers (obj) {
        for (const key in obj) {
          if (!isNaN(obj[key])) {
            obj[key] = parseFloat(obj[key]);
          }
        }
        return obj;
      }
      if (item.deviceType === 3) {
        var deviceData = item.deviceDefaultValuesJson;
        deviceData.SystolicMin = parseFloat(deviceData.SystolicMin).toFixed(1);
        deviceData.SystolicMax = parseFloat(deviceData.SystolicMax).toFixed(1);
        deviceData.HeartBeatMin = parseFloat(deviceData.HeartBeatMin).toFixed(1);
        deviceData.HeartBeatMax = parseFloat(deviceData.HeartBeatMax).toFixed(1);
        deviceData.DiastolicMin = parseFloat(deviceData.DiastolicMin).toFixed(1);
        deviceData.DiastolicMax = parseFloat(deviceData.DiastolicMax).toFixed(1);
      }
      if (item.deviceType === 1) {
        var temp = item.deviceDefaultValuesJson;
        if (this.userSettings.Units.toLowerCase() === 'imperial') {
          // Convert temperature values to Celsius and round to one decimal place
          temp.MinTemperature = fromTo(parseFloat(temp.MinTemperature), 'F', 'C').toFixed(1);
          temp.MaxTemperature = fromTo(parseFloat(temp.MaxTemperature), 'F', 'C').toFixed(1);
        }

        // Round temperature values to one decimal place
        temp.MinTemperature = parseFloat(temp.MinTemperature).toFixed(1);
        temp.MaxTemperature = parseFloat(temp.MaxTemperature).toFixed(1);

        return JSON.stringify(parseNumbers(temp));
      } if (item.deviceType === 6) {
        var gluco = item.deviceDefaultValuesJson;
        if (this.userSettings.Concentration === 'mmol/l') {
          return JSON.stringify(parseNumbers(gluco));
        }
        gluco.BloodSugarLevelMin = (gluco.BloodSugarLevelMin / 18).toFixed(1);
        gluco.BloodSugarLevelMax = (gluco.BloodSugarLevelMax / 18).toFixed(1);
        return JSON.stringify(parseNumbers(gluco));
      } if (item.deviceType === 7) {
        var glucoCol = item.deviceDefaultValuesJson;
        if (this.userSettings.Concentration === 'mmol/l') {
          return JSON.stringify(parseNumbers(glucoCol));
        }
        glucoCol.BloodSugarLevelMin = (glucoCol.BloodSugarLevelMin / 18).toFixed(1);
        glucoCol.BloodSugarLevelMax = (glucoCol.BloodSugarLevelMax / 18).toFixed(1);

        glucoCol.CholesterolLevelMin = (glucoCol.CholesterolLevelMin / 38.67).toFixed(1);
        glucoCol.CholesterolLevelMax = (glucoCol.CholesterolLevelMax / 38.67).toFixed(1);
        return JSON.stringify(parseNumbers(parseNumbers(glucoCol)));
      } else {
        return JSON.stringify(parseNumbers(item.deviceDefaultValuesJson));
      }

      // return JSON.stringify(val.deviceDefaultValuesJson);
    },
    convertedValue (item) {
      if (item.deviceType === 1) {
        var temp = JSON.parse(item.deviceDefaultValuesJson);
        if (this.userSettings.Units.toLowerCase() === 'imperial') {
          temp.MinTemperature = fromTo(parseFloat(temp.MinTemperature), 'C', 'F').toFixed(1);
          temp.MaxTemperature = fromTo(parseFloat(temp.MaxTemperature), 'C', 'F').toFixed(1);
        }
        return temp;
      } if (item.deviceType === 6) {
        var gluco = JSON.parse(item.deviceDefaultValuesJson);
        if (this.userSettings.Concentration === 'mmol/l') {
          return gluco;
        }
        gluco.BloodSugarLevelMin = (gluco.BloodSugarLevelMin * 18).toFixed(1);
        gluco.BloodSugarLevelMax = (gluco.BloodSugarLevelMax * 18).toFixed(1);
        return gluco;
      } if (item.deviceType === 7) {
        var glucoCol = JSON.parse(item.deviceDefaultValuesJson);
        if (this.userSettings.Concentration === 'mmol/l') {
          return glucoCol;
        }
        glucoCol.BloodSugarLevelMin = (glucoCol.BloodSugarLevelMin * 18).toFixed(1);
        glucoCol.BloodSugarLevelMax = (glucoCol.BloodSugarLevelMax * 18).toFixed(1);

        glucoCol.CholesterolLevelMin = (glucoCol.CholesterolLevelMin * 38.67).toFixed(1);
        glucoCol.CholesterolLevelMax = (glucoCol.CholesterolLevelMax * 38.67).toFixed(1);
        return glucoCol;
      } else {
        return JSON.parse(item.deviceDefaultValuesJson);
      }
    },
    async initialize () {
      this.assignDevices = [];
      this.monitoringDevice = [];
      this.filteredDevices = [];

      await this.$store.dispatch('medicaldevice/getmedicaldevicesforpatientV2', this.detailedUser.userData.id).then(res => {
        res.data.medicalDevicesList[0].assignedMedicalDevicesList.forEach(item => {
          var manufacturerName = this.verifiersFromRepo.find(element => element.lowValue === item.manufacturerId).shortDescription;
          var tableElement = {
            treatmentPlanId: res.data?.patientActiveTreatmentPlan?.id ?? 0,
            id: item.id,
            deviceDefaultValuesJsonNames: Object.keys(JSON.parse(item.deviceDefaultValuesJson)),
            deviceDefaultValuesJson: this.convertedValue(item),
            deviceDefaultExtremeValuesJson: item.deviceDefaultExtremeValuesJson,
            name: item.deviceName,
            nameEsp: item.deviceNameEsp,
            deviceType: item.deviceType,
            manufacturer: manufacturerName,
            manufacturerId: item.manufacturerId,
            isManualMeasurement: item.isManualMeasurement,
            deviceIdentifier: item.deviceIdentifier,
            isThisDeviceAssignedToMonitoringPlan: item.isThisDeviceAssignedToMonitoringPlan,
          };
          this.assignDevices.push(tableElement);
        });

        this.trustedHubIdentifier = res.data.medicalDevicesList[0].hubIdentifier;

        if (res.data.patientActiveTreatmentPlan) {
          res.data.patientActiveTreatmentPlan.treatmentPlanDevicesList.forEach(item => {
            var monitoringElement = {
              id: item.id,
              deviceType: item.deviceType,
              measurementPeriod: item.measurementPeriod,
              measurementsCount: item.measurementsCount,
              treatmentPlanId: item.treatmentPlanId,
              deviceTypeTitle: item.deviceTypeTitle,
              deviceTypeTitleEsp: item.deviceTypeTitleEsp,
              time1: item.time1 ? item.time1 : '',
              time2: item.time2 ? item.time2 : '',
              time3: item.time3 ? item.time3 : '',
              time4: item.time4 ? item.time4 : '',
            };
            this.monitoringDevice.push(monitoringElement);
          });
        }
      });

      var filteredPossibleDevices = [];
      await this.$store.dispatch('medicaldevice/getPossibleDevices').then(res => {
        const allPossibleDevicesList = res;
        allPossibleDevicesList.forEach(item => {
          // Check if the device already exists in assignDevices
          const existsInAssignDevices = this.assignDevices.some(element =>
              element.deviceType === item.deviceType && element.manufacturerId === item.manufacturerId,
          );

          if (!existsInAssignDevices) {
            // Check if the device is not already in filteredPossibleDevices
            const existsInFilteredDevices = filteredPossibleDevices.some(existingItem =>
                existingItem.deviceType === item.deviceType && existingItem.manufacturerId === item.manufacturerId,
            );

            if (!existsInFilteredDevices) {
              var itemsSelect = {
                body: {
                  deviceType: item.deviceType,
                  manufacturerId: item.manufacturerId,
                  manufacturerName: item.manufacturerName,
                  treatmentPlanId: this.patientActiveTreatmentPlan.id,
                  hubIdentifier: this.trustedHubIdentifier,
                  deviceName: item.deviceName,
                },
                deviceName: item.deviceName + ' (' + item.manufacturerName + ')',
                deviceNameEsp: item.deviceNameEsp + ' (' + item.manufacturerName + ')',
              };
              filteredPossibleDevices.push(itemsSelect);
            }
          }
        });

        // Add the filtered devices to this.filteredDevices
        this.filteredDevices = [...this.filteredDevices, ...filteredPossibleDevices];
      });
      // this.deviceType = this.currentDevice.lowValue
    },
    async isManual (val) {
      var body = {
        patientId: this.detailedUser.userData.id,
        val: val,
      };
      await this.$store.dispatch('medicaldevice/updateIsManualMeasurementForMedicalDevice', body).then(async () => {
        this.initialize();
      });
    },
    async addDevice () {
      this.createDropdownStatus = true;
    },
  },
};
</script>
