<template>
  <v-card
      elevation="0"
      v-if="surveyData"
  >
    <v-card-title class="card-header text-left wellness-assessment-add-custom">{{$t('add-assessment')}}
      <v-spacer></v-spacer>
      <v-icon color="white" @click="closeDrawer">mdi mdi-close</v-icon>
    </v-card-title>
    <!-- Role -->
    <v-card-text>
      <v-form
          ref="form"
          v-model="valid"
      >
        <v-text-field
            class="my-3"
            outlined
            dense
            hide-details
            :label="$t('assessment-name')"
            required
            v-model="assessmentName"
            :key="'textField' + componentKey"
        ></v-text-field>
        <!--Type-->
<!--        <v-autocomplete-->
<!--            class="my-3"-->
<!--            :label="$t('type')"-->
<!--            dense-->
<!--            :items="getMentalHealthCategoryForForm"-->
<!--            :item-text="this.$i18n.locale === 'en' ? `shortDescription` : `shortDescriptionEsp`"-->
<!--            hide-details-->
<!--            outlined-->
<!--            item-value="lowValue"-->
<!--            required-->
<!--            v-model="mentalHealthCategory"-->
<!--        ></v-autocomplete>-->
<!--        <i v-if="showCustomAsessment()" class="d-flex ml-1">{{$t('predefined-question-text')}}</i>-->
        <!--Description-->
        <v-textarea
            class="no-resize my-3"
            outlined
            multi-line
            :rows="5"
            dense
            hide-details
            :label="$t('description')"
            required
            no-resize
            v-model="description"
            :key="'textArea' + componentKey"
        ></v-textarea>

        <div v-if="description !==''  ||  assessmentName !=='' ">
                  <v-row  dense class="mt-5 mx-0">
                    <span class="black--text font-size15 font-weight-bold ">{{ $t('questions') }}</span>
                  </v-row>
        </div>
        <v-row v-if="description !=='' || assessmentName !==''"   dense class="my-3 d-flex justify-center align-center">
          <v-btn
              class="white-color capitalize-text"
              elevation="0"
              color="primary2"
              width="98%"
              @click="createQuestion"
          >
            <v-icon dense color="white" class="mdi mdi-plus"></v-icon>
            {{$t('question')}}
          </v-btn>
        </v-row>
        <v-card class="pa-5"   v-if="showSubmitButton">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                  v-if="showSubmitButton"
                  class="my-3"
                  outlined
                  v-model="newQuestion"
                  dense
                  hide-details
                  :label="$t('question')"
              ></v-text-field>
            </v-col>
          </v-row>
          <div v-if="showSubmitButton">
            <v-row dense class="mx-0 pb-3" >
              <v-autocomplete
                  v-model="selectedVerifier"
                  :items="getSurveyTypesForForm"
                  :item-text="$i18n.locale === 'en' ? 'shortDescription' : 'shortDescriptionEsp'"
                  item-value="lowValue"
                  hide-details
                  outlined
                  dense
                  :label="$t('type')"
              ></v-autocomplete>
            </v-row>
            <v-text-field
                class="my-3"
                outlined
                v-if="showSubmitButton && selectedVerifier !==3 "
                v-model="newAnswer"
                dense
                hide-details
                :label="$t('answer')"
            ></v-text-field>
            <v-col cols="12" class="text-left d-flex font-weight-bold mt-n2 ml-n3 color-black" v-if="showSubmitButton">
              <v-simple-checkbox
                  color="primary"
                  dense
                  v-model="isMandatory"
              >
              </v-simple-checkbox>
              <span>Is Mandatory</span>
            </v-col>
            <v-btn
                v-if="showSubmitButton"
                color="primary2"
                elevation="0"
                width="100%"
                class="mt-2 capitalize-text white-color"
                @click="submitQuestion"
            >
              {{$t('create-question')}}
            </v-btn>
          </div>
        </v-card>

        <div class="margin-top20">
          <v-expansion-panels class="display-block">
            <draggable :list="surveyById.questionsListWithAnswers" @end="(event) => endQuestionsDrag(event, surveyById.questionsListWithAnswers)">
              <v-expansion-panel dense v-for="(question, index) in surveyById.questionsListWithAnswers" :key="index"
              >
                <v-expansion-panel-header class="custom-header py-0 my-0" >
                  <v-row dense>
                    <v-col >
                    </v-col>
                    <v-col class="pa-0 d-flex align-center">
                      <v-icon color="main_black" class="mr-2 mb-2" @click.stop="deleteQuestion(question, index)">mdi-trash-can-outline</v-icon>
                    </v-col>
                  </v-row>
                  <v-col >
                    <template v-slot:actions class>
                      <v-icon dense >mdi-chevron-down</v-icon>
                    </template>
                    <v-avatar size="22px" color="chips" class="mr-1">
                      <span class="text-white">{{index+1}}</span>
                    </v-avatar>
                    <span class="font-weight-medium font-size15">{{ question.question }}</span>
                  </v-col>
                </v-expansion-panel-header>
                <v-expansion-panel-content >
                  <!--Question-->
                  <v-text-field
                      class="my-3"
                      outlined
                      dense
                      clear-icon="mdi-backspace-outline"
                      clearable
                      @click:clear="deleteOption(question)"
                      hide-details
                      v-model="question.question"
                      :label="$t('question')"
                      required
                  ></v-text-field>
                  <!--                    TRETO-->
                  <v-row dense class="mx-0 pb-3">
                    <v-autocomplete
                        v-model="question.answerTypeId"
                        :items="getSurveyTypesForForm"
                        :item-text="$i18n.locale === 'en' ? 'shortDescription' : 'shortDescriptionEsp'"
                        item-value="lowValue"
                        hide-details
                        outlined
                        class="z-index1000"
                        dense
                        @change="updateQuestion(question)"
                        :label="$t('type')"
                    ></v-autocomplete>
                  </v-row>
                  <div v-if="question.answerTypeTitle !== 'Custom text' && question.answerTypeTitle !== null && question.answerTypeTitle !== undefined">
                    <v-row><span class="mt-4 ml-3 mb-4 font-weight-bold font-size16" v-if="question.answerTypeId!==3">{{$t('answers')}}</span></v-row>
                    <draggable :list="question.offeredAnswersList" tag="tbody" @end="(event) => endDrag(event, question.offeredAnswersList)">
                      <v-row dense class="mt-n3 mb-0" v-for="(answer, answerIndex) in question.offeredAnswersList" :key="answerIndex">
                        <v-col cols="12" v-if="question.answerTypeId!==3 ">
                          <v-text-field
                              class="my-2"
                              outlined
                              dense
                              hide-details
                              v-model="answer.possibleAnswer"
                          >
                            <template v-slot:append>
                              <div class="margin-left70">
                                <v-icon
                                    color="main_black"
                                    @click="editAnswer(question, answer, answerIndex)"
                                >
                                  mdi-content-save
                                </v-icon>
                                <v-icon
                                    color="main_black"
                                    @click="deleteAnswer(answer, index)"
                                >
                                  mdi-trash-can-outline
                                </v-icon>
                              </div>

                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="8" class="align-left text-left d-flex my-1" v-if="question.answerTypeId!==3">
                          <v-simple-checkbox
                              color="primary"
                              dense
                              v-model="answer.triggerNotification"
                              @click="editAnswer(question, answer, answerIndex)"
                          ></v-simple-checkbox>
                          <span class="font-weight-bold">{{$t('trigger-notification')}}</span>
                        </v-col>
                        <v-col cols="4" class="d-flex align-center" v-if="surveyById.mentalHealthCategoryId===27">
                          <i class="mr-8">Points:</i>
                          <v-text-field v-model="answer.scorePoints" :value="answer.scorePoints" hide-details dense @blur="editAnswerRow(answer)" outlined></v-text-field>
                        </v-col>
                      </v-row>
                    </draggable>
                  </div>
                  <v-text-field
                      class="my-3"
                      outlined
                      v-if="!showFirstButton"
                      v-model="newAnswer"
                      dense
                      hide-details
                      :label="$t('answer')"
                  ></v-text-field>
                  <v-row dense class="mx-n1 mt-3" >
                    <v-col v-if="!showFirstButton">
                      <v-btn
                          class="capitalize-text"
                          elevation="0"
                          outlined
                          width="100%"
                          @click="createAnswer(index, question)"
                      >
                        <v-icon color="black">mdi-plus</v-icon>
                        <span class="mx-2 text-transform-none font-size15">{{$t('add-answer')}}</span>
                      </v-btn>
                    </v-col>
                    <v-col v-if="showFirstButton && question.answerTypeId!==3">
                      <v-btn
                          elevation="0"
                          outlined
                          width="100%"
                          class="capitalize-text"
                          @click="addAnswer()"
                      >
                        <v-icon color="black">mdi-plus</v-icon>
                        <span class="mx-2 text-transform-none font-size15">{{ $t('add-new-answer') }}</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </draggable>
          </v-expansion-panels>
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions class="fixed-card-actions">
      <v-row dense class="mb-0">
        <v-col cols="6">
          <v-btn
              @click="closeDrawer"
              elevation="0"
              class="capitalize-text cancel-button-style"
          >
            {{$t('cancel')}}
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
              width="100%"
              :disabled="!this.createdSurvey"
              class="save-button-style"
              @click="updateSurveyTemplate"
          >
            {{$t('save')}}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import draggable from 'vuedraggable';
import { showSuccessAlert } from '@/utils/utilities';
export default {
  components: {
    draggable,
  },
	name: 'DashboardCoreAppBar',
	data: () => ({
    surveyCreated: false,
    createdSurvey: false,
		showFirstButton: true,
		isMandatory: false,
		checkbox: false,
		showSubmitButton: false,
		newQuestion: '',
		showAddButton: false,
    enableAddQuestions: false,
		valid: true,
    componentKey: 0,
    itemForEdit: {},
		surveyId: null,
		newAnswer: [],
		notifyAnswer: false,
		category: [],
		assessmentName: '',
		type: '',
		description: '',
		questionsList: [],
		questionOptions: [],
		selectedVerifier: -1,
		mentalHealthCategory: null,
		questions: [
			{
				name: '',
				type: 3,
				options: [],
			},
		],
	}),
	computed: {
		...mapState({
			surveyById: (state) => state.mentalhealth.survey,
			userData: (state) => state.authentication.userData,

		}),
		...mapGetters({
			// hospitalId: 'authentication/getHospitalId',
			getMentalHealthCategoryForForm: 'verifiers/getMentalHealthCategoryForForm',
			getSurveyTypesForForm: 'verifiers/getSurveyTypesForForm',
		}),
		hospitalId () {
			const hospitalId = this.userData.hospitalAssignedData.id;
			return hospitalId;
		},
	},
  beforeDestroy () {
    this.assessmentName = '';
  },
  methods: {
    editAnswer (question, answer) {
      const index = question.offeredAnswersList.indexOf(answer);
      if (index === -1) {
        // Answer not found in the list, handle error or return early
        return;
      }
      const updatedAnswer = {
        stQuestionId: question.id,
        possibleAnswer: answer.possibleAnswer,
        orderNumber: index + 1,
        triggerNotification: answer.triggerNotification, // Use the triggerNotification property of the answer
        id: answer.id,
      };
      this.$store.dispatch('mentalhealth/updateAnswer', updatedAnswer).then(() => {
        this.$store.dispatch('mentalhealth/getSurveysById', this.surveyById.id);
        showSuccessAlert(this.$t('answer-updated'));
      });
    },
    resetComponent () {
      // Reset firstname or any other component state as needed
      this.assessmentName = '';
      this.description = '';

      // Change the key to trigger component reset
      this.componentKey += 1;
    },
    surveyData () {
      return this.surveyById;
    },
    endDrag (event, items) {
      const itemId = items[event.newDraggableIndex].id;
      const orderId = event.newDraggableIndex + 1;

      // Update the order of items in the items array
      items.forEach((item, index) => {
        item.orderNumber = index + 1;
      });

      const updatedAnswer = {
        stOfferedAnswerId: itemId,
        changedOrderNumberValue: orderId,
      };

      // Call the API only once for the moved item
      this.$store.dispatch('mentalhealth/stofferAnswerReorder', updatedAnswer);
    },
    // showCustomAsessment () {
    //   const excludedNumbers = [27, 30, 31, 32];
    //   return excludedNumbers.includes(this.mentalHealthCategory);
    // },
    endQuestionsDrag (event, items) {
      const questionId = items[event.newDraggableIndex].id;
      const orderId = event.newDraggableIndex + 1;

      const updatedQuestion = {
        StQuestionId: questionId,
        ChangedOrderNumberValue: orderId,
      };
      this.$store.dispatch('mentalhealth/stquestionReorder', updatedQuestion);
    },
		saveAndReload () {
			this.createSurvey();
      this.closeDrawer();
		},
		selectQuestionType (question, value) {
			question.type = value;
			this.updateQuestion(question, this.questionsList.indexOf(question));
		},
		addAnswer () {
			this.showFirstButton = false;
			this.showAddButton = true;
			this.questions.push({
				type: 3,
				name: '',
				options: [],
				id: null,
			});
		},
		editItem (item, index, type) {
			this.disableAddAnswer = true;
			this.newIndex = index;
			if (type === 1) {
				this.dialog2 = true;
			} else {
				this.dialog1 = true;
			}
			this.title = 2;
			this.itemForEdit = {
				item: item,
				index: index,
			};
		},
		createQuestion () {
      if (!this.surveyCreated) {
        this.createSurvey();
        this.surveyCreated = true;
      }
			this.showSubmitButton = true;
			this.questions.push({
				type: 3,
				name: '',
				options: [],
				id: null,
			});
		},
		// updateTriggerNotification (question, answerIndex) {
		// 	const triggerNotificationValue = this.question.offeredAnswersList[answerIndex].triggerNotification;
		// 	// Update the triggerNotification property in the specific answer object
		// 	this.$set(question.offeredAnswersList[answerIndex], 'triggerNotification', !triggerNotificationValue);
		// },
		createAnswer (index, question) {
			var createAnswer = {
				stQuestionId: question.id, // Use the id of the current question
				possibleAnswer: this.newAnswer,
				orderNumber: question.offeredAnswersList.length + 1,
				triggerNotification: this.notifyAnswer,
			};
			this.$store.dispatch('mentalhealth/createAnswer', createAnswer).then((res) => {
				if (res.data.triggerNotification) {
					this.selected.push(
						res.data.id,
					);
				}
				this.showFirstButton = true;
				this.newAnswer = '';
				this.$store.dispatch('mentalhealth/getSurveysById', this.surveyId);
			});
		},
		updateQuestion (question, index) {
			const object = {
				question: question.question,
				answerTypeId: question.answerTypeId,
				orderNumber: 1,
				isMandatory: this.isMandatory,
				id: question.id,
			};
			this.$store.dispatch('mentalhealth/updateQuestion', object);
		},
    async submitQuestion () {
      const questionObject = {
        surveyTemplateId: this.surveyId,
        question: this.newQuestion,
        answerTypeId: this.selectedVerifier,
        orderNumber: this.questions.length,
        isMandatory: this.isMandatory,
      };

      // Dispatch the action and wait for it to complete
      const questionResponse = await this.$store.dispatch('mentalhealth/createQuestion', questionObject);

      // Update the local state only after the action is completed
      this.questions.push({
        type: questionResponse.answerTypeId,
        name: questionResponse.question,
        options: [],
        id: questionResponse.id,
      });
      if (this.selectedVerifier === 3) {
        this.newAnswer = '';
      }
      // Create the associated answer
      const answerObject = {
        stQuestionId: questionResponse.id,
        possibleAnswer: this.newAnswer,
        orderNumber: 1,
        triggerNotification: this.notifyAnswer,
      };

      // Call the API to create the answer
      const answerResponse = await this.$store.dispatch('mentalhealth/createAnswer', answerObject);

      if (answerResponse.data.triggerNotification) {
        this.selected.push(answerResponse.data.id);
      }

      this.showSubmitButton = false;
      this.newQuestion = '';
      this.newAnswer = '';
      this.selectedVerifier = '';
      this.isMandatory = false;

      // Optionally, you can dispatch an action to get the updated survey data
      this.$store.dispatch('mentalhealth/getSurveysById', this.surveyId);
    },
		deleteOption (questionOption) {
			this.questionOptions = this.questionOptions.filter(
				(option) => option !== questionOption,
			);
		},
		createSurvey () {
			const object = {
				templateName: this.assessmentName,
				mentalHealthCategoryId: this.mentalHealthCategory,
				templateDescription: this.description,
				hospitalId: this.hospitalId, // this.userData.hospitalAssignedData.id,
			};
        this.$store.dispatch('mentalhealth/createSurvey', object).then(res => {
        this.enableAddQuestions = true;
				this.$store.dispatch('mentalhealth/getMentalHealthSurveyTemplates', this.hospitalId);
				this.surveyId = res.id;

				// this.mentalHealthCategory = '';
				// this.assessmentName = '';
				// this.description = '';
			});
			this.surveyCreate = true;
      this.createdSurvey = true;
		},
      // updateSurveyTemplate
    async updateSurveyTemplate () {
      const object = {
        id: this.surveyId,
        templateName: this.assessmentName,
        mentalHealthCategoryId: 0,
        templateDescription: this.description,
        status: this.surveyData.status ? 1 : 0,
      };

      await this.$store.dispatch('mentalhealth/updateSurveyTemplate', object).then(res => {
        // Handle success or any other actions you need to take
        this.$store.dispatch('mentalhealth/getMentalHealthSurveyTemplates', this.hospitalId);
        this.surveyId = res.id;
      });
      this.resetComponent();
      this.closeDrawer();
    },
		deleteQuestion (item) {
			this.$store.dispatch('mentalhealth/deleteQuestion', item.id).then(() => {
				if (this.update) {
					this.$emit('update-survey');
				} else {
					this.$store.dispatch('mentalhealth/getSurveysById', this.surveyId);
				}
        showSuccessAlert(this.$t('question-deleted'));
			});
		},
		deleteAnswer (item, index) {
			this.questions[index].options = this.questions[index].options.filter(element => element.possibleAnswer !== item.possibleAnswer);
			this.$store.dispatch('mentalhealth/deleteAnswer', item.id).then(res => {
        this.$store.dispatch('mentalhealth/getSurveysById', this.surveyId);
        showSuccessAlert(this.$t('answer-deleted'));
      });
		},
		closeDrawer () {
      // clean up the current select survey template from the store
      this.$store.commit('mentalhealth/CLEAR_CURRENT_SURVEY_TEMPLATE', null);

       this.surveyCreated = false;

			this.valid = false;
      this.$store.commit('SET_DETAILS_DRAWER', false);
      this.resetComponent();
    },
	},
	watch: {

	},

};
</script>
