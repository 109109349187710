<template>
  <v-card class="mx-auto" elevation="0">
    <v-card-title class="card-header fixed-card-title">
      {{$t('add-patient')}}
      <v-spacer></v-spacer>
      <v-icon class="white-color" @click="closeDrawer">mdi-close</v-icon>
    </v-card-title>
    <v-card-text class="px-2 py-16">
      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
      >
        <v-container>
          <v-row dense>
            <v-col cols="6">
              <v-text-field
                  outlined
                  hide-details
                  dense
                  v-model="userDetails.firstName"
                  :rules="[v => !!v || 'Item is required']"
                  :label="$t('name')"
                  required
                  :key="componentKey"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                  outlined
                  :rules="[v => !!v || 'Item is required']"
                  hide-details
                  dense
                  v-model="userDetails.lastName"
                  :label="$t('surname')"
                  required
                  :key="componentKey"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6">
              <v-autocomplete
                  outlined
                  hide-details
                  dense
                  :label="$t('gender')"
                  :items="patients.gender"
                  v-model="userDetails.gender"
                  :key="componentKey"
              >
                <template v-slot:item="{ item }">
                  {{ item.text }}
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-text-field
                  ref="phoneNumber"
                  outlined
                  hide-details
                  dense
                  type="phone"
                  v-model="phone"
                  :label="$t('phone')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6" class="d-flex align-center">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-simple-checkbox
                      color="primary"
                      dense
                      class="align-center"
                      v-model="isGenericEmailAccount"
                      @click="handleGenericEmailAccountCheckbox"
                      v-on="on"
                  >
                  </v-simple-checkbox>
                </template>
                <span>{{$t('generic-email-account-tooltip')}}</span>
              </v-tooltip>
              <span>{{$t('generic-email-account-label')}}</span>
            </v-col>
            <v-col
                cols="6"
                v-if="isGenericEmailAccount === false"
            >
              <validation-provider
                  ref="email"
                  :name="$t('email')"
                  rules="required|email"
                  v-slot="{ errors }">
                <v-text-field
                    :label="$t('email')"
                    v-model="userDetails.email"
                    dense
                    type="email"
                    :rules="[v => !!v || 'Item is required']"
                    hide-details
                    outlined
                    :error-messages="(emailError = errors)"
                    :key="componentKey"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6">
              <v-text-field
                  outlined
                  hide-details
                  dense
                  v-model="userDetailsPolicyNumber"
                  :label="$t('policyNumber')"
                  :key="componentKey"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  :rules="[v => !!v || 'Item is required']"
                  hide-details
                  dense
                  item-value="lowValue"
                  :items= "getHealthInsuranceProgram"
                  :item-text= "domain=> domain.shortDescription"
                  v-model="healthInsuranceProgramID"
                  :label="$t('insurance')"
                  :key="componentKey"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col
                cols="6"
            >
              <validation-provider
                  :name="$t('height')"
                  v-slot="{ errors }">
                <v-text-field
                  :label="userSettings.Units === 'METRIC' ? $t('height-in-cm') : $t('height-in-ft')"
                  v-model="heightInCm"
                  outlined
                  type="number"
                  dense
                  hide-details
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col
                cols="6"
            >
              <validation-provider
                  :name="$t('weight')"
                  v-slot="{ errors }">
              <v-text-field
                  :label="userSettings.Units === 'METRIC' ? $t('weight-in-kg') : $t('weight-in-lbs')"
                  v-model="weightInKg"
                  outlined
                  dense
                  type="number"
                  hide-details
                  :error-messages="errors"
              ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row
              dense
          >
            <v-col cols="6">
            <BirthdatePicker :inputValue.sync="birthdate" />
            </v-col>
            <v-col cols="6">
              <v-text-field
                  outlined
                  hide-details
                  dense
                  v-model="userDetails.mrn"
                  :label="$t('mrn')"
              ></v-text-field>
            </v-col>

          </v-row>
          <v-row dense>
            <v-col class="pa-1" cols="6">
              <v-text-field
                  outlined
                  @blur="handleLostFocus"
                  hide-details
                  dense
                  :rules="[v => !!v || 'Item is required']"
                  :label="$t('country')"
                  v-model="addressInfo.country"
                  required
                  :key="componentKey"
              ></v-text-field>
            </v-col>
            <v-col class="pa-1" cols="6">
              <v-text-field
                  outlined
                  hide-details
                  @blur="handleLostFocus"
                  dense
                  :rules="[v => !!v || 'Item is required']"
                  :label="$t('state')"
                  v-model="addressInfo.state"
                  required
                  :key="componentKey"
              ></v-text-field>
            </v-col>
            <v-col class="pa-1" cols="6">
              <v-text-field
                  outlined
                  @blur="handleLostFocus"
                  hide-details
                  dense
                  :rules="[v => !!v || 'Item is required']"
                  :label="$t('city')"
                  v-model="addressInfo.city"
                  required
                  :key="componentKey"
              ></v-text-field>
            </v-col>
            <v-col class="pa-1" cols="6">
              <v-text-field
                  outlined
                  hide-details
                  @blur="handleLostFocus"
                  dense
                  :rules="[v => !!v || 'Item is required']"
                  :label="$t('zip')"
                  v-model="addressInfo.zip"
                  required
                  :key="componentKey"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="11">
              <v-text-field
                  ref="address"
                  @blur="handleLostFocus"
                  v-model="patientAddress"
                  :label="$t('address')"
                  outlined
                  hide-details
                  dense
                  :rules="[v => !!v || 'Item is required']"
                  :key="componentKey"
              >
              </v-text-field>
            </v-col>
            <v-col cols="1" class="d-flex align-center">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-simple-checkbox
                      color="primary"
                      dense
                      class="align-center"
                      v-model="useSameAddressLikeHospital"
                      @click="handleCheckboxChange"
                      v-on="on"
                  ></v-simple-checkbox>
                </template>
                <span>{{$t('addressForSameHospital')}}</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row class="justify-space-between my-2 mx-0" dense>
            <v-btn-toggle
                v-model="smoker"
                row
                active-class="chips white--text"
                mandatory
                class="white"
 >
              <v-btn
                  class="tabs-class"
                  value='0'
                  >{{$t("non-smoker")}}</v-btn>
              <v-btn
                  class="tabs-class"
                  value='1'
              >{{$t("smoker")}}</v-btn>
            </v-btn-toggle>
            <v-btn-toggle
                row
                active-class="chips white--text"
                mandatory
                v-model="glucoseAndCholesterolUnit"
                class="white"
            >
              <v-btn
                  class="tabs-class"
                  value='mg/dl'
                  @click="setGlucoseAndCholesterolUnit('mg/dl')"
              >MG/DL</v-btn>
              <v-btn
                  class="tabs-class"
                  value='mmol/l'
                  @click="setGlucoseAndCholesterolUnit('mmol/l')"
              >MMOL/L</v-btn>
            </v-btn-toggle>
          </v-row>
          <v-row dense class="d-flex justify-space-between my-2 mx-0">
            <v-btn-toggle
                v-model="userDetails.language"
                row
                active-class="chips white--text"
                mandatory
                class="white"
            >
              <v-btn
                  class="tabs-class"
                  value='ENG'
              >{{$t("english")}}</v-btn>
              <v-btn
                  class="tabs-class"
                  value='ESP'
              >{{$t("spanish")}}</v-btn>
            </v-btn-toggle>
            <v-btn-toggle
                row
                mandatory
                v-model="scaleAndTempUnit"
                class="white"
                active-class="chips white--text"
            >
              <v-btn
                  class="tabs-class"
                  value='IMPERIAL'
                  @click="setImperialOrMetric('IMPERIAL')"
              >{{$t("imperial")}}</v-btn>
              <v-btn
                  class="tabs-class"
                  value='METRIC'
                  @click="setImperialOrMetric('METRIC')"
              >{{$t("metric")}}</v-btn>
            </v-btn-toggle>
          </v-row>
          <v-row dense class="mx-0">
            <v-tabs
                :show-arrows="true"
                v-model="tab"
                background-color="secondary"
                grow
                hide-slider
                dense
                height="30px"
                centered icons-and-text
                active-class="chips white--text ma-0"
            >
              <v-tab v-for="(tab, index) in tabsHeader" :key="index">{{ tab.title }}</v-tab>
              <v-tab-item v-for="(tab, index) in tabsHeader" :key="index">

                <v-textarea
                    v-if="tab.title !=='Diagnoses'"
                    v-model="tab.content"
                    :label="tab.title"
                    solo
                    outlined
                    auto-grow
                    hide-details
                    dense
                    class="mt-2"
                >
                </v-textarea>
                <div v-if="tab.title==='Diagnoses'" class="margin-top20">
                  <v-autocomplete
                      @input="handleAutoCompleteInput"
                      v-model="selectedVerifier"
                      :items="getICD10Codes"
                      :item-text="$i18n.locale === 'en' ? 'shortDescription' : 'shortDescriptionEsp'"
                      item-value="lowValue"
                      hide-details
                      outlined
                      dense
                      :label="$t('main-category-of-diagnosis')"
                      :menu-props="{ maxWidth: '450'}"
                  >
                  </v-autocomplete>
                  <v-row dense>
                    <v-col  cols="12">
                      <v-data-table
                          class="ma-0 pa-0"
                          dense
                          height="300px"
                          :headers="headers"
                          fixed-header
                          :no-data-text="$t('no-data-available')"
                          :items="filteredCategory"
                          :items-per-page="-1"
                          hide-default-footer
                          :loading="loadingData"
                      >
                        <!-- ... -->
                        <template v-slot:item="{ item }">
                          <tr @click="selectedDiagnose(item)">
                            <td>
                              <v-checkbox
                                  hide-details
                                  :value="item"
                                  class="mt-n1"
                              >
                              </v-checkbox></td>
                            <td class="text-left font-bold"><span>{{ item.icd10Code }}</span></td>
                            <td class="text-left"><span>{{ item.fullDescription }}</span></td>
                            <!-- <td><v-icon dense color="primary">mdi-plus-box</v-icon></td> -->
                          </tr>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </div>
              </v-tab-item>
            </v-tabs>
          </v-row>
          <v-row dense class="mx-0 pt-2">
            <v-data-table
                :height="dataTableHeight"
                :headers="headers2"
                :items="getDoctorAndNurse"
                fixed-header
                class="full-width"
                hide-default-footer
                :items-per-page="-1"
            >
              <template v-slot:item="{item}">
                <tr>
                  <td class="text-left font-size13 font-weight-bold" >{{item.firstName}} {{item.lastName}}</td>
                  <td>
                    <v-checkbox
                        :disabled="item.id === primaryDoctorSelected ? true : false"
                        class="pl-8 ma-0 mb-1"
                        hide-details
                        color="primary"
                        :value="item.id"
                        v-model="staffSelected"
                    >
                    </v-checkbox>
                  </td>
                  <td>
                    <v-radio-group class="pa-0 ma-0" hide-details v-model="primaryDoctorSelected">
                      <v-radio
                          color="primary"
                          v-if="item.roleId === 3"
                          :key="item.id"
                          :value="item.id"
                      >
                      </v-radio>
                    </v-radio-group>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>

    <v-card-actions class="fixed-card-actions">
      <v-row dense class="pa-0">
        <v-col cols="6">
          <v-btn
              @click="closeDrawer"
              elevation="0"
              class="cancel-button-style capitalize-text"
          >
            {{$t('cancel')}}
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
              @click="loader = 'loading'"
              :disabled="isSaveButtonDisabled"
              class="save-button-style"
          >
            {{$t('save')}}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { closeWhite } from '@/assets';
import { mapGetters, mapState } from 'vuex';
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';
import BirthdatePicker from '@/views/Client/components/BirthdatePicker.vue';
import { fromTo } from '@/utils/unit-converter';
import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';

export default {
	name: 'DashboardCoreAppBar',
	components: {
		BirthdatePicker,
	},
	data: () => ({
    selectedDiagnoses: [],
    useSameAddressLikeHospital: true,
    isGenericEmailAccount: false,
    search: '',
    loadingData: false,
    showDiagnoses: false,
    category: [],
    selectedVerifier: '',
    componentKey: 0,
    healthInsuranceProgramID: -1,
		userDetails: {
			firstName: '',
			lastName: '',
			email: '',
		},
		dataStatus: null,
		loader: null,
		loading: null,
		primaryDoctorSelected: [],
		staffSelected: [],
		patientAddress: '',
		validationErrors: [],
		addressInfo: {
			address: '',
			zip: '',
			city: '',
			state: '',
			country: '',
			lat: 32.798718741846955,
			lng: -96.79917112574036,
		},
		smoker: '0',
		heightInCm: null,
		birthdate: '2000-01-01',
		weightInKg: null,
		tab: null,
		userDetailsPolicyNumber: '',
		closeWhite: closeWhite,
		emailError: false,
		scaleAndTempUnit: 'IMPERIAL',
		glucoseAndCholesterolUnit: 'mg/dl',
		patients: {
			gender: [{ text: 'Male', value: 1 }, { text: 'Female', value: 2 }],
		},
		phone: '+1',
		timezone: null,
		formattedAddress: null,
		valid: true,
		phoneErrors: false,
	}),
	computed: {
		...mapState({
			userData: (state) => state.authentication.userData,
      detailedUser: (state) => state.users.detailedUser,
		}),
		...mapGetters({
      roleName: 'authentication/getRole',
      getICD10Codes: 'verifiers/getICD10Codes',
			getHealthInsuranceProgram: 'verifiers/getHealthInsuranceProgram',
			hospitalId: 'authentication/getHospitalId',
			userSettings: 'authentication/getUserSettings',
			roles: 'roles/getRolesForForm',
			getDoctorAndNurse: 'hospitals/getDoctorAndNurse',
			getTimeZone: 'verifiers/getTimeZone',
		}),
    dataTableHeight () {
      if (this.$vuetify.breakpoint.width <= 1200 && this.$vuetify.breakpoint.height <= 802) {
        return 200;
      } else {
        return 300;
      }
    },
    filteredCategory () {
      if (!this.search) {
        return this.category; // Return all items if search is empty
      }
      const keyword = this.search.toLowerCase();
      return this.category.filter(item => {
        return (
            item.icd10Code.toLowerCase().includes(keyword) ||
            item.fullDescription.toLowerCase().includes(keyword)
        );
      });
    },
		genderValue () {
			if (this.userDetails.gender === 1) {
				return 'M';
			} else if (this.userDetails.gender === 2) {
				return 'F';
			}
			return null;
		},
    isSaveButtonDisabled () {
      return (
      !this.userDetails.firstName ||
      !this.userDetails.lastName ||
      !this.userDetails.email ||
      !this.formattedAddress ||
      !this.patientAddress ||
      !this.addressInfo.zip ||
      !this.addressInfo.city ||
      !this.addressInfo.state ||
      !this.addressInfo.country ||
      !this.birthdate ||
      this.primaryDoctorSelected.length === 0 ||
      !this.staffSelected
      );
    },
		headers () {
			const headers = [
        { text: '', sortable: false },
        { text: this.$t('name'), sortable: false, align: 'start' },
        { text: this.$t('description'), sortable: false, align: 'start' },
			];
			return headers;
		},
    headers2 () {
      const headersMedicalTeam = [
        { text: this.$t('name'), sortable: false, align: 'start' },
        { text: '', sortable: false, align: 'start' },
        { text: '', sortable: false, align: 'start' },
      ];
      return headersMedicalTeam;
    },
		tabsHeader () {
			return [
				{ title: this.$t('treatments'), content: '' },
				{ title: this.$t('allergies'), content: '' },
        { title: this.$t('diagnoses'), content: '' },
        // { title: this.$t('problem-list'), content: '' },
			];
		},
	},
  async mounted () {
		await this.$store.dispatch('verifiers/getVerifiersForDomainHealthInsurance');
		await this.getStaff();
		await this.getTimeZones();
		await this.addressData();
    await this.handleCheckboxChange();
    await this.handleGenericEmailAccountCheckbox();
    if (!isValidPhoneNumber(this.phone)) {
			this.phoneErrors = true;
		}
    await this.getVerifiersForDomain();
		this.dataStatus = this?.userData?.patientMetadata?.isManualMeasurement;
	},
	methods: {
    resetComponent () {
      // Reset firstname or any other component state as needed
      this.userDetails.firstName = '';
      this.userDetails.lastName = '';
      this.userDetails.email = '';
      this.addressInfo.address = '';
      this.userDetails.gender = '';
      this.userDetailsPolicyNumber = '';
      this.healthInsuranceProgramID = '';
      this.patientAddress = '';
      this.addressInfo.zip = '';
      this.addressInfo.city = '';
      this.addressInfo.state = '';
      this.addressInfo.country = '';

      // Change the key to trigger component reset
      this.componentKey += 1;
    },
    async insertPatientDiagnose (selectedICD10Code, selectedFullDescription) {
      const body = {
        patientUserId: this.detailedUser.userData.id,
        staffId: this.userData.id,
        hospitalId: this.userData.hospitalAssignedData.id,
        diagnosisCode: selectedICD10Code,
        diagnoseDescription: selectedFullDescription,
      };
      const response = await this.$store.dispatch('patientdiagnosis/createPatientDiagnosis', body);
      if (response.resFlag) {
        showSuccessAlert(this.$t('icdCodeAdded'));

        this.detailedUser.userData.patientDiagnosisList.push(response.data);
      } else {
        showErrorAlert(this.$t('icdCodeError'));
      }
    },
    async getDiagnosesByCategory (item) {
      const response = await this.$store.dispatch('patientmetadata/getDiagnosesByCategory', item);
      if (response && response?.data) {
        this.category = response?.data;
      }
    },
    async getVerifiersForDomain () {
      await this.$store.dispatch('verifiers/getICD10CodeForDomainSpecialization');
    },
    async selectedDiagnose (item) {
      if (this.selectedDiagnoses.includes(item)) {
        // remove in this case
        for (let i = 0; i < this.selectedDiagnoses.length; i++) {
          if (this.selectedDiagnoses[i].icd10Code === item.icd10Code) {
            this.selectedDiagnoses.splice(i, 1);
            break;
          }
        }
      } else {
        // add to array
        this.selectedDiagnoses.push(item);
      }
    },
		async handleAutoCompleteInput () {
      if (!this.selectedVerifier) {
        return;
      }
      try {
        this.loadingData = true;
        const body = this.selectedVerifier.toString();
        const response = await this.$store?.dispatch('patientmetadata/getDiagnosesByCategory', body);
        if (response && response.data) {
          this.category = response?.data;
          this.loadingData = false;
        }
      } catch (error) {
        // console.log(error);
      }
    },
    async handleGenericEmailAccountCheckbox () {
      if (this.isGenericEmailAccount === true) {
        this.userDetails.email = 'generic@vitalcheckups.com'; // checked
      } else {
        this.userDetails.email = ''; // not checked
      }
    },
    async handleCheckboxChange () {
      // Toggle the value of the checkbox
      if (this.useSameAddressLikeHospital) {
        this.formattedAddress = this.userData.hospitalAssignedData.address;
        this.patientAddress = this.userData.hospitalAssignedData.address;
        var hospitalAddress = {
          address: this.userData.hospitalAssignedData.address,
          zip: this.userData.hospitalAssignedData.zip,
          city: this.userData.hospitalAssignedData.city,
          state: this.userData.hospitalAssignedData.state,
          country: this.userData.hospitalAssignedData.country,
          lat: this.userData.hospitalAssignedData.addressLatitude,
          lng: this.userData.hospitalAssignedData.addressLongitude,
        };
        this.addressInfo = hospitalAddress;
        if (this.addressInfo.lat !== null) {
          const tz = await this.timeZone(this.addressInfo.lat, this.addressInfo.lng);
          if (tz) {
            this.timezone = tz.zoneName;
          }
        }
      } else {
        this.formattedAddress = '';
        this.patientAddress = '';
        this.addressInfo = {};
      }
    },
		async manageMedicalTeam (res) {
			const body = {
				hospitalId: this.userData.hospitalAssignedData.id,
				patientId: res.id,
				primaryDoctorId: this.primaryDoctorSelected,
				medicalTeamTitle: res.firstName + ' ' + res.lastName + ' Team',
				medicalTeamDescription: res.firstName + ' ' + res.lastName,
				status: 1,
			};
			await this.$store
				.dispatch('medicalteam/createMedicalTeam', body)
				.then((res) => {
					const createdMedicalTeamId = res.id;
					this.assignMemberToMedicalTeam(createdMedicalTeamId);
				});
		},
		async assignMemberToMedicalTeam (medicalTeamId) {
			const bodyAssign = {
				medicalTeamId: medicalTeamId,
				memberUserIdList: this.staffSelected,
			};
			await this.$store.dispatch('medicalteam/assignMultipleMembersToMedicalTeam', bodyAssign);
			this.$emit('false');
		},
		handleLostFocus () {
			if (this.patientAddress !== '' && this.addressInfo.city !== '' && this.addressInfo.zip !== '' && this.addressInfo.state !== '' && this.addressInfo.country !== '') {
				this.userDetails.address = this.patientAddress + ', ' + this.addressInfo.city + ', ' + this.addressInfo.state + ' ' + this.addressInfo.zip + ', ' + this.addressInfo.country;
				this.locationDetails();
			}
		},
		setImperialOrMetric (unit) {
			this.scaleAndTempUnit = unit;
			this.getPreferences();
		},
		setGlucoseAndCholesterolUnit (unit) {
			this.glucoseAndCholesterolUnit = unit;
			this.getPreferences();
		},
		getPreferences () {
			const preferences = {
				Units: this.scaleAndTempUnit,
				Concentration: this.glucoseAndCholesterolUnit,
			};
			return preferences;
		},
		checkValid () {
			this.validationErrors = [];
			if (this.phoneErrors) {
				this.validationErrors.push('phone');
			}
			if (this.emailError.length > 0) {
				this.validationErrors.push('email');
			}
			if (this.addressInfo.address === '') {
				this.validationErrors.push('address');
			}
			if (this.birthdate === '') {
				this.validationErrors.push('birthdate');
			}
			if (this.validationErrors.length > 0) {
				return false;
			} else return true;
		},
		async createPatient () {
			const bodyForPatientCreate = {
				firstName: this.userDetails.firstName,
				lastName: this.userDetails.lastName,
				phone: this.phone.split(' ').join(''),
				email: this.userDetails.email,
				address: this.addressInfo.address,
				zip: this.addressInfo.zip,
				city: this.addressInfo.city,
				state: this.addressInfo.state,
				country: this.addressInfo.country,
				addressLatitude: this.addressInfo.lat,
				addressLongitude: this.addressInfo.lng,
				// MentalHealth: false,
				// MentalHealth: this.userDetails.mentalHealth,
				username: this.userDetails.email,
				gender: this.genderValue,
				birthdate: this.birthdate,
				roleId: 5, // PATIENT
				language: this.userDetails.language,
				userTimezone: this.timezone,
				userSettingsJson: JSON.stringify(this.getPreferences()),
				IsManualMeasurement: this.dataStatus,
				hospitalId: this.userData.hospitalAssignedData.id,
        isEmailAccountProvided: !this.isGenericEmailAccount,
			};

			await this.$store.dispatch('users/createUser', bodyForPatientCreate).then(async (res) => {
        // FD: i commented this, because i dont see why we need
        // this.userData = res;

        if (res.resFlag === true) {
          // handle patient metadata
					const metaDataBody = {
						userId: res.data.id,
						heightInCm: this.userSettings.Units === 'METRIC' ? parseInt(this.heightInCm) : parseFloat(fromTo(this.heightInCm, 'ft-us', 'cm').toFixed(2)),
						weightInKg: this.userSettings.Units === 'METRIC' ? parseInt(this.weightInKg) : parseFloat(fromTo(this.weightInKg, 'lb', 'kg').toFixed(2)),
						ssn: this.ssn,
						medicalRegistrationNumber: this.userDetails.mrn,
						patientIdentifier: this.patientIdentifier,
						healthInsurance: this.healthInsuranceProgramID,
						memberIdPolicyNumber: this.userDetailsPolicyNumber,
						isCigaretteSmoker: this.smoker === '1',
						alcoolConsumtion: this.userDetails.alcoolConsumtion,
						prescriptions: this.tabsHeader.find(tab => tab.title === this.$t('treatments')).content,
						// mentalHealth: false,
						// mentalHealth: this.userDetails.mentalHealth,
						diagnose: this.tabsHeader.find(tab => tab.title === this.$t('allergies')).content,
						IsManualMeasurement: this.dataStatus,
					};
					await this.$store.dispatch('patientmetadata/editPatientMetaData', metaDataBody);

          // handle medical team
          await this.manageMedicalTeam(res.data);

          // handle patient diagnosis
          for (let i = 0; i < this.selectedDiagnoses.length; i++) {
            const body = {
              patientUserId: res.data.id,
              staffId: this.userData.id,
              hospitalId: this.userData.hospitalAssignedData.id,
              diagnosisCode: this.selectedDiagnoses[i].icd10Code,
              diagnoseDescription: this.selectedDiagnoses[i].fullDescription,
            };
            await this.$store.dispatch('patientdiagnosis/createPatientDiagnosis', body);
          }

          showSuccessAlert(this.$t('successfully-created-patient'));

					await this.getPatients(); // refresh the patients list
          this.resetComponent();
					this.closeDrawer();
				} else {
					showErrorAlert(res.msg);
				}
			});
		},
		async addressData () {
			this.addressInfo = {
				address: this.userDetails.address,
				zip: this.addressInfo?.zip,
				city: this.addressInfo?.city,
				state: this.addressInfo?.state,
				country: this.addressInfo?.country,
				lat: this.addressInfo?.addressLatitude,
				lng: this.addressInfo?.addressLongitude,
			};
		},
		async locationDetails () {
			if (this.userDetails.address) {
				this.waiting = true;
				const geoLocation = await this.$store.dispatch(
					'googleAPI/getGeoLocation',
					this.userDetails.address,
				);
				this.waiting = false;
				if (!geoLocation) {
					this.validationErrors.push('address');
					this.addressInfo = {
						address: this.userDetails.address,
						zip: this.addressInfo.zip,
						formattedAddress: this.userDetails.address,
						city: this.addressInfo.city,
						state: this.addressInfo.state,
						country: this.addressInfo.country,
						lat: 32.798718741846955,
						lng: -96.79917112574036,
					};
					this.timezone = 'America/Chicago';
					return;
				}
				if (geoLocation.address === undefined || geoLocation.address == null) {
					this.validationErrors.push('address');
					this.addressInfo = {
						address: this.userDetails.address,
						zip: this.addressInfo.zip,
						formattedAddress: this.userDetails.address,
						city: this.addressInfo.city,
						state: this.addressInfo.state,
						country: this.addressInfo.country,
						lat: 32.798718741846955,
						lng: -96.79917112574036,
					};
					this.timezone = 'America/Chicago';
					return;
				}
				const addressComponents = geoLocation.address;
				this.addressInfo.lat = (geoLocation.lat === undefined || geoLocation.lat === null) ? 0.01 : geoLocation.lat;
				this.addressInfo.lng = (geoLocation.lon === undefined || geoLocation.lon === null) ? 0.01 : geoLocation.lon;
				this.formattedAddress = (geoLocation.display_name === undefined || geoLocation.display_name === null) ? this.userDetails.address : geoLocation.display_name;
				this.addressInfo.address = this.formattedAddress;
				this.addressInfo.city = addressComponents.city || addressComponents.village || addressComponents.suburb || 'N/A';
				this.addressInfo.state = addressComponents.state || 'N/A';
				this.addressInfo.country = addressComponents.country || 'N/A';
				this.addressInfo.zip = addressComponents.postcode || 'N/A';

				if (this.addressInfo.lat !== null) {
					const tz = await this.timeZone(this.addressInfo.lat, this.addressInfo.lng);
					if (tz) {
						this.timezone = tz.zoneName;
					}
				}
			}
		},
		async timeZone (lat, lng) {
			const object = {
				lat: lat,
				lng: lng,
			};
			const timeZone = await this.$store.dispatch('googleAPI/getTimeZone', object);
			return timeZone;
		},
		getTimeZones () {
			this.$store.dispatch('verifiers/getTimeZones').then(() => {
			});
		},
		closeDrawer () {
			this.userDetails.firstName = '';
			this.userDetails.lastName = '';
			this.userDetails.mrn = '';
			this.userDetails.email = '';
			this.phone = '+1';
			this.formattedAddress = null;
			this.patientAddress = '';
			this.addressInfo.zip = '';
			this.addressInfo.city = '';
			this.addressInfo.state = '';
			this.addressInfo.country = '';
			this.addressInfo.lat = 32.798718741846955;
			this.addressInfo.lng = -96.79917112574036;
			this.smoker = '0';
			this.glucoseAndCholesterolUnit = 'mg/dl';
			this.scaleAndTempUnit = 'IMPERIAL';
			this.userDetails.language = 'eng';
			this.heightInCm = null;
			this.birthdate = '2000-01-01';
			this.weightInKg = null;
			this.tab = null;
			this.valid = false;
			this.fileUpload = null;
			this.primaryDoctorSelected = [];
			this.staffSelected = [];
      this.isGenericEmailAccount = false;
      this.useSameAddressLikeHospital = false;
      this.selectedDiagnoses = [];

			this.$store.commit('SET_DETAILS_DRAWER', false);
      this.resetComponent();
		},
		async getStaff () {
			await this.$store.dispatch('hospitals/getRelatedUsersForHospital', this.userData.hospitalAssignedData.id);
		},
		async getPatients () {
			const hospitalIdFromStore = this.userData.hospitalAssignedData.id;
			if (this.roleName === 'HOSPITAL_ADMIN' || this.roleName === 'DOCTOR' || this.roleName === 'NURSE') {
				await this.$store.dispatch('hospitals/getRelatedPatientsV3', hospitalIdFromStore)
					.then(() => {
						// this.filteredUsers = this.relatedPatients;
						// this.filteredUsers?.forEach((user) => {
						// 	user.fullname = user.firstName + ' ' + user.lastName;
						// 	user.age = this.calculateAge(user.birthdate);
						// 	user.sex = user.gender === 'M' ? this.$t('male') : this.$t('female');
						// 	user.phone = new AsYouType().input(user.phone);
						// 	if (user.hasMedicalTeam === true) {
						// 		user.medTeam = 1;
						// 	}
						// 	user.patientHasDoneReadingForTodayValue = user.patientHasDoneReadingForToday === true ? this.$t('yes') : this.$t('no');
						// 	this.verifiersFromRepo.forEach(v => {
						// 		if (user.status === v.lowValue) {
						// 			user.statusShortDescription = v.shortDescription;
						// 			user.statusLongDescription = v.longDescription;
						// 		}
						// 	});
						// });
						// this.loading = false;
					});
			} else if (this.roleName === 'ORGANIZATION_ADMIN') {
				// this.filteredUsers = [];
				// this.loading = false;
			} else {
				// this.filteredUsers = [];
				// this.loading = false;
			}
		},
	},
	watch: {
		primaryDoctorSelected (val) {
			// if (!this.staffSelected.includes(val)) {
      if (Number.isInteger(val) && !this.staffSelected.includes(val)) {
				this.staffSelected.push(val);
			}
		},
		async loader () {
			if (this.loader !== null) {
				const l = this.loader;
				this[l] = !this[l];

				await this.createPatient()
					.then(() => {
						this[l] = false;
					})
					.catch(() => {
						this[l] = false;
					});

				this.loader = null;
			}
		},
		phone (val) {
			if (val !== undefined) {
				if (!isValidPhoneNumber(val)) {
					this.phoneErrors = true;
				} else {
					this.phoneErrors = false;
				}
				this.phone = new AsYouType().input(val);
			}
		},
	},
};
</script>
